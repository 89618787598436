import Newsletter from '@modules/Newsletter'
import useContenthubContext from '../context'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { PageContext } from '@providers/PageContext'
import { useContext } from 'react'

const CHNewsletter = ({ moduleName }) => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const { state } = useContenthubContext()
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === moduleName)
  const { labels: apiLabels = {} } = useLabelsFromAPI(['NewsLetterSubscription'], siteLanguage)
  const newsLetterLabels = apiLabels?.NewsLetterSubscription
  return (
    currentBlockData ?
      <div id='newsletter'>
        <Newsletter
          newsletter_headline={newsLetterLabels?.newsLetterTitle}
          newsletter_description={newsLetterLabels?.newsLetterSubTitle}
          newsletter_subscribe_CTA={newsLetterLabels?.subscribeBtnText}
          newsletter_subscribe_description={newsLetterLabels?.subscribeCtaText}
          newsletter_success_description={newsLetterLabels?.successDescriptionText}
          id={currentBlockData.id}
          isDividerHide
        />
      </div>
      : null
  )
}

export default CHNewsletter