import callNewsAPI, { storyType } from '@utils/callNewsAPI'
import { News } from '@services'
import { replaceCommaSeparatedWithFullAPIResults, returnObjectAndIndexFromNameAndList } from '@utils/contenthub'
import _ from 'lodash'
import type INews from 'types/INews'
import { ApiResponse } from 'apisauce'

const contenthubMetaInfo = async ({ urlContentData, siteLanguage }) => {
  const allStoriesIds: number[] = []
  const objectCopy = { ...urlContentData.data.values.page }

  // Spotlight
  const Spotlight = returnObjectAndIndexFromNameAndList('Today\'s Spotlight', urlContentData.data.values.page.modules)

  // Infocus
  const InFocus = returnObjectAndIndexFromNameAndList('In Focus: Fair', urlContentData.data.values.page.modules)

  // The Art market
  const TheArtMarket = returnObjectAndIndexFromNameAndList('The Art Market', urlContentData.data.values.page.modules)

  const LatestStories = returnObjectAndIndexFromNameAndList(urlContentData.data.values.page.category ?
    'Category | latest stories' : 'Latest Stories', urlContentData.data.values.page.modules)

  // Most Popular
  const MostPopular = returnObjectAndIndexFromNameAndList('Most Popular', urlContentData.data.values.page.modules)

  // In Partnership with
  const InPartnershipwith = returnObjectAndIndexFromNameAndList('In Partnership with', urlContentData.data.values.page.modules)

  // Recommended for you
  const RecommendedForYou = returnObjectAndIndexFromNameAndList('Recommended for you', urlContentData.data.values.page.modules)


  // Highlighted Category Carousel
  const HighlightedCategoryCarousel = returnObjectAndIndexFromNameAndList('Highlighted Category(Carousel)', urlContentData.data.values.page.modules)

  // Highlighted Category Listing
  const HighlightedCategoryListing = returnObjectAndIndexFromNameAndList('Highlighted Category(Listing)', urlContentData.data.values.page.modules)

  // Discover more
  const DiscoverMore = returnObjectAndIndexFromNameAndList('Discover More(Multiple categories)', urlContentData.data.values.page.modules)

  // Highlighted Story
  const HighlightedStory = returnObjectAndIndexFromNameAndList('Highlighted story (full width)', urlContentData.data.values.page.modules)

  // Series Compilations
  const SeriesCompilations = returnObjectAndIndexFromNameAndList('Series compilation', urlContentData.data.values.page.modules)

  // Highlighted Story Card
  const HighlightedStoryCard = returnObjectAndIndexFromNameAndList('Highlighted story (Card)', urlContentData.data.values.page.modules)

  // Gathering all the IDs in all the Stories
  InFocus.object && allStoriesIds.push(...InFocus.object.fields.closureStories.split(','))
  HighlightedStory.object && allStoriesIds.push(...HighlightedStory?.object?.fields?.stories.split(','))
  SeriesCompilations.object && allStoriesIds.push(...SeriesCompilations?.object?.fields?.stories.split(','))
  HighlightedStoryCard.object && allStoriesIds.push(...HighlightedStoryCard?.object?.fields?.stories.split(','))

  // Excluding the stories from the modules latest stories and most popular
  const spotlightStoryId = Spotlight?.object?.fields?.spotlightedStoriesIds || []
  const inFocuseStoryId = InFocus?.object?.fields?.stories?.split(',') || []
  const artMarketStoryId = TheArtMarket?.object?.fields?.stories?.split(',') || []
  const inPartnershipwithStoryId = InPartnershipwith?.object?.fields?.stories?.split(',') || []
  const RecommendedForYouStoryId = RecommendedForYou?.object?.fields?.stories?.split(',') || []
  const HighlightedCategoryCarouselStoryId = HighlightedCategoryCarousel?.object?.fields?.stories?.split(',') || []
  const HighlightedCategoryListingStoryId = HighlightedCategoryListing?.object?.fields?.stories?.split(',') || []
  const DiscoverMoreStoryId0 = DiscoverMore?.object?.fields?.stories0?.split(',') || []
  const DiscoverMoreStoryId1 = DiscoverMore?.object?.fields?.stories1?.split(',') || []
  const DiscoverMoreStoryId2 = DiscoverMore?.object?.fields?.stories2?.split(',') || []

  if (InFocus?.object?.fields?.closureStories) {
    inFocuseStoryId.push(InFocus?.object?.fields?.closureStories)
  }
  let excludedStoriesIds: Array<string[]> = []

  if (spotlightStoryId?.length > 0 && Spotlight?.object?.fields.selectedMode === 0) {
      excludedStoriesIds.push(spotlightStoryId)
  }
  if (inFocuseStoryId?.length > 0) {
    excludedStoriesIds.push(inFocuseStoryId)
  }
  if (artMarketStoryId?.length > 0 && TheArtMarket?.object?.fields.selectedMode === 0) {
    excludedStoriesIds.push(artMarketStoryId)
  }
  if (inPartnershipwithStoryId?.length > 0 && InPartnershipwith?.object?.fields.selectedMode === 0) {
    excludedStoriesIds.push(inPartnershipwithStoryId)
  }
  if (RecommendedForYouStoryId?.length > 0 && RecommendedForYou?.object?.fields.selectedMode === 0) {
    excludedStoriesIds.push(RecommendedForYouStoryId)
  }
  if (HighlightedCategoryCarouselStoryId?.length > 0 &&
    HighlightedCategoryCarousel?.object?.fields.selectedMode === 0) {
    excludedStoriesIds.push(HighlightedCategoryCarouselStoryId)
  }
  if (HighlightedCategoryListingStoryId?.length > 0 && HighlightedCategoryListing?.object?.fields.selectedMode === 0) {
    excludedStoriesIds.push(HighlightedCategoryListingStoryId)
  }

  if(DiscoverMore?.object?.fields.selectedMode === 0) {
    if (DiscoverMoreStoryId0?.length > 0) {
      excludedStoriesIds.push(DiscoverMoreStoryId0)
    }
    if (DiscoverMoreStoryId1?.length > 0) {
      excludedStoriesIds.push(DiscoverMoreStoryId1)
    }
    if (DiscoverMoreStoryId2?.length > 0) {
      excludedStoriesIds.push(DiscoverMoreStoryId2)
    }
  }

  let defaultNewsAPIParams: Paths.SearchUsingGET.QueryParameters = {
    type: 'NEWS_ARTICLE',
    offset: 0,
    limit: 4,
    orderBy: 'ORDER_BY_PUBLISHDATE',
    // @ts-ignore --> Doesn't exist in API. Mistery
    locale: siteLanguage
  }

  // Setting the values into the respective objects
  //Spotlight block
  let spotLightBlockResponse
  const tags = Spotlight.object?.fields.tags
  if (Spotlight.object) {
    if (Spotlight.object.fields.selectedMode === 1) {
      const spotLightBlockparams = {
        ...defaultNewsAPIParams,
        excludedTagIds: Spotlight.object?.fields?.excludedTags ? Spotlight.object?.fields?.excludedTags.join(',') : [],
        tagIds: tags ? tags?.join(',') : []
      }
      spotLightBlockResponse = callNewsAPI(spotLightBlockparams, 'isSpotLightStories')
    } else {
      const spotLightBlockparams = {
        ...defaultNewsAPIParams,
        ids: Spotlight.object.fields.spotlightedStoriesIds.join(',')
      }
      spotLightBlockResponse = callNewsAPI(spotLightBlockparams, 'isSpotLightStories')
    }
  }


  let latestStoriesResponse
  // for magazine landing page
  if (urlContentData.data.values.page.category) {
    // for category landing page
    const HighlightedStoryId = HighlightedStory?.object?.fields?.stories?.split(',') || []
    const HighlightedStoryCardId = HighlightedStoryCard?.object?.fields?.stories?.split(',') || []
    const SeriesCompilationsId = SeriesCompilations?.object?.fields?.stories?.split(',') || []

    if (HighlightedStoryId?.length > 0) {
      excludedStoriesIds.push(HighlightedStoryId)
    }
    if (HighlightedStoryCardId?.length > 0) {
      excludedStoriesIds.push(HighlightedStoryCardId)
    }
    if (SeriesCompilationsId?.length > 0) {
      excludedStoriesIds.push(SeriesCompilationsId)
    }
    let latestStoriesParams: any = {
      ...defaultNewsAPIParams,
      limit: excludedStoriesIds.length > 4 ? excludedStoriesIds.length : 4,
      magazineCategoryIds: urlContentData.data.values.page.category?.id,
      excludedIds: excludedStoriesIds.join(',')
    }
    latestStoriesResponse = callNewsAPI(latestStoriesParams, 'isLatestStories')
  } else {
      let latestStoriesParams: any = {}
      let spotlightResponse = await spotLightBlockResponse
      let ids = spotlightResponse?.data?.items?.map(item => item.id) || []
      let excludedStoriesIdsForLatestStories = [...ids]
      latestStoriesParams = {
        ...defaultNewsAPIParams,
        limit: excludedStoriesIdsForLatestStories.length > 4 ? excludedStoriesIdsForLatestStories.length : 4,
        excludedIds: excludedStoriesIdsForLatestStories.join(',')
      }
      latestStoriesResponse = callNewsAPI(latestStoriesParams, 'isLatestStories')
  }

  //In Partner with block
  let InPartnershipWithResponse
  if (InPartnershipwith.object) {
    if (InPartnershipwith.object.fields.selectedMode === 1) {
      const inPartnershipwithparams = {
        ...defaultNewsAPIParams,
        excludedTagIds: InPartnershipwith.object.fields?.excludedTags ? InPartnershipwith.object.fields?.excludedTags?.join(',') : [],
        tagIds: InPartnershipwith.object.fields.tags ? InPartnershipwith.object.fields.tags.join(',') : [],
        magazineCategoryIds: InPartnershipwith.object.fields?.selectedCategoryAutoMode as any || ''
      }
      InPartnershipWithResponse = callNewsAPI(inPartnershipwithparams, 'isInPartnershipwith')
    } else {
      const InPartnershipParams = {
        ...defaultNewsAPIParams,
        ids: InPartnershipwith.object?.fields?.stories
      }
      InPartnershipWithResponse = callNewsAPI(InPartnershipParams, 'isInPartnershipwith')
    }
  }

  // In focus block
  let InfocusBlockResponse
  if (InFocus.object) {
    const inFocusParams = {
      ...defaultNewsAPIParams,
      ids: InFocus.object?.fields?.stories
    }
    InfocusBlockResponse = callNewsAPI(inFocusParams, 'isInFocusStories')
    allStoriesIds.push(...InFocus.object.fields.closureStories.split(','))
  }

  // The Art Market block
  let TheArtMarketResponse
  if (TheArtMarket.object) {
    if (TheArtMarket.object.fields.selectedMode === 1) {
      const tagIds = TheArtMarket.object.fields.tags
      const theArtMarketParams = {
        ...defaultNewsAPIParams,
        excludedTagIds: TheArtMarket.object?.fields?.excludedTags ? TheArtMarket.object.fields?.excludedTags.join(',') : [],
        tagIds: tagIds ? tagIds.join(',') : '',
        magazineCategoryIds: TheArtMarket.object?.fields?.selectedCategoryAutoMode as any || ''
      }
      TheArtMarketResponse = callNewsAPI(theArtMarketParams, 'isTheArtMarket')
    } else {
      const theArtMarketParams = {
        ...defaultNewsAPIParams,
        ids: TheArtMarket.object.fields.stories
      }
      TheArtMarketResponse = callNewsAPI(theArtMarketParams, 'isTheArtMarket')
    }
  }

  //Most popular block
  let mostPopularResponse
  if (MostPopular.object) {
    let excludedSpotlightStoryIds: any = []
    if(Spotlight?.object?.fields.selectedMode === 1) {
      let spotlightResponse = await spotLightBlockResponse
      let ids = spotlightResponse?.data?.items?.map(item => item.id) || []
      excludedSpotlightStoryIds = ids
    } else {
      excludedSpotlightStoryIds.push(spotlightStoryId)
    }
    const mostPopularParams = {
      ...defaultNewsAPIParams,
      limit: excludedSpotlightStoryIds.length > 4 ? excludedSpotlightStoryIds.length : 5,
      ids: MostPopular.object.fields.mostViewedNewsIds.join(','),
      excludedIds: excludedSpotlightStoryIds.join(',') as any
    }
    mostPopularResponse = callNewsAPI(mostPopularParams, 'isMostPopularStories')
  }

  // Recommended for you block
  let RecommendedForYouResponse
  if (RecommendedForYou.object) {
    const mostPopularParams = {
      limit: 5,
      ids: RecommendedForYou.object.fields.stories
    }
    RecommendedForYouResponse = callNewsAPI(mostPopularParams, 'isRecommendedForYouStories')
  }

  // highlightedCatgeory Carousel block
  let highlightedCategoryCarouselResponse
  if (HighlightedCategoryCarousel.object) {
    if (HighlightedCategoryCarousel.object.fields.selectedMode === 1) {
      const highlightedCategoryCarouselParams = {
        ...defaultNewsAPIParams,
        excludedTagIds: HighlightedCategoryCarousel.object?.fields?.excludedTags ? HighlightedCategoryCarousel.object?.fields?.excludedTags.join(',') : [],
        tagIds: HighlightedCategoryCarousel.object.fields.tags.join(','),
        magazineCategoryIds: HighlightedCategoryCarousel.object.fields?.selectedCategoryAutoMode?.value as any || ''
      }
      highlightedCategoryCarouselResponse = callNewsAPI(highlightedCategoryCarouselParams, 'isHighlightedCategoryCarousel')
    } else {
      const highlightedCategoryCarouselParams = {
        ...defaultNewsAPIParams,
        ids: HighlightedCategoryCarousel.object.fields.stories
      }
      highlightedCategoryCarouselResponse = callNewsAPI(highlightedCategoryCarouselParams, 'isHighlightedCategoryCarousel')
    }
  }

  // highlightedCatgeory Listing block
  let highlightedCategoryListingResponse
  if (HighlightedCategoryListing.object) {
    if (HighlightedCategoryListing.object.fields.selectedMode === 1) {
      const highlightedCategoryListingParams = {
        ...defaultNewsAPIParams,
        excludedTagIds: HighlightedCategoryListing.object?.fields?.excludedTags ? HighlightedCategoryListing.object?.fields?.excludedTags.join(',') : [],
        tagIds: HighlightedCategoryListing.object.fields.tags.join(','),
        magazineCategoryIds: HighlightedCategoryListing.object.fields?.selectedCategoryAutoMode?.value as any || ''
      }
      highlightedCategoryListingResponse = callNewsAPI(highlightedCategoryListingParams, 'isHighlightedCategoryListing')
    } else {
      const highlightedCategoryListingParams = {
        ...defaultNewsAPIParams,
        ids: HighlightedCategoryListing.object.fields.stories
      }
      highlightedCategoryListingResponse = callNewsAPI(highlightedCategoryListingParams, 'isHighlightedCategoryListing')
    }
  }

  // discover more block
  let discoverMoreResponse0
  let discoverMoreResponse1
  let discoverMoreResponse2
  if (DiscoverMore.object) {
    if (DiscoverMore.object.fields.selectedMode === 1) {
      const discoverMoreParams0 = {
        ...defaultNewsAPIParams,
        excludedTagIds: DiscoverMore?.object?.fields?.excludedTags?.[0].length > 0 ? DiscoverMore.object.fields.excludedTags?.[0]?.join(',') : '',
        tagIds: DiscoverMore?.object?.fields?.tags?.[0].length > 0 ? DiscoverMore.object.fields.tags?.[0]?.join(',') : '',
        magazineCategoryIds: DiscoverMore?.object?.fields?.selectedCategoryAutoMode?.[0]?.value as any
      }
      const discoverMoreParams1 = {
        ...defaultNewsAPIParams,
        excludedTagIds: DiscoverMore?.object?.fields?.excludedTags?.[1].length > 0 ? DiscoverMore.object.fields.excludedTags?.[1]?.join(',') : '',
        tagIds: DiscoverMore?.object?.fields?.tags?.[1].length > 0 ? DiscoverMore.object.fields.tags?.[1]?.join(',') : '',
        magazineCategoryIds: DiscoverMore?.object?.fields?.selectedCategoryAutoMode?.[1]?.value as any
      }
      const discoverMoreParams2 = {
        ...defaultNewsAPIParams,
        excludedTagIds: DiscoverMore?.object?.fields?.excludedTags?.[2].length > 0 ? DiscoverMore.object.fields.excludedTags?.[2]?.join(',') : '',
        tagIds: DiscoverMore?.object?.fields?.tags?.[2].length > 0 ? DiscoverMore.object.fields.tags?.[2]?.join(',') : '',
        magazineCategoryIds: DiscoverMore?.object?.fields?.selectedCategoryAutoMode?.[2]?.value
      }
      discoverMoreResponse0 = callNewsAPI(discoverMoreParams0, 'isDiscoverBlockStories0')
      discoverMoreResponse1 = callNewsAPI(discoverMoreParams1, 'isDiscoverBlockStories1')
      discoverMoreResponse2 = callNewsAPI(discoverMoreParams2, 'isDiscoverBlockStories2')
    } else {
      const discoverMoreParams0 = {
        ...defaultNewsAPIParams,
        ids: DiscoverMore.object.fields.stories0
      }
      const discoverMoreParams1 = {
        ...defaultNewsAPIParams,
        ids: DiscoverMore.object.fields.stories1
      }
      const discoverMoreParams2 = {
        ...defaultNewsAPIParams,
        ids: DiscoverMore.object.fields.stories2
      }
      discoverMoreResponse0 = callNewsAPI(discoverMoreParams0, 'isDiscoverBlockStories0')
      discoverMoreResponse1 = callNewsAPI(discoverMoreParams1, 'isDiscoverBlockStories1')
      discoverMoreResponse2 = callNewsAPI(discoverMoreParams2, 'isDiscoverBlockStories2')
    }
  }

  // Highlighted Story block
  if (HighlightedStory.object) {
    allStoriesIds.push(...HighlightedStory.object.fields.stories.split(','))
  }

  // Series Compilations block
  let SeriesCompilationsResponse
  if (SeriesCompilations.object) {
    const SeriesCompilationsParams = {
      ...defaultNewsAPIParams,
      limit: 10,
      ids: SeriesCompilations.object.fields.stories
    }
    SeriesCompilationsResponse = callNewsAPI(SeriesCompilationsParams, 'isSeriesCompilationsBlock')
  }

  // Highlighted Story Card block
  if (HighlightedStoryCard.object) {
    allStoriesIds.push(...HighlightedStoryCard.object.fields.stories.split(','))
  }

  // API Promise to retrieve all the Stories
  const promises: Promise<ApiResponse<INews>>[] = []

  // clean repeateds and empty strings
  const allStoriesIdsNoRepeats = _.compact(_.uniq(allStoriesIds)).map(Number)

  allStoriesIdsNoRepeats.forEach((item) => {
    promises.push(News.get(item) as never)
  })
  promises.push(latestStoriesResponse)
  promises.push(spotLightBlockResponse)
  promises.push(InfocusBlockResponse)
  promises.push(mostPopularResponse)
  promises.push(TheArtMarketResponse)
  promises.push(InPartnershipWithResponse)
  promises.push(RecommendedForYouResponse as never)
  promises.push(highlightedCategoryListingResponse)
  promises.push(highlightedCategoryCarouselResponse)
  promises.push(discoverMoreResponse0)
  promises.push(discoverMoreResponse1)
  promises.push(discoverMoreResponse2)
  promises.push(SeriesCompilationsResponse)

  // This TS is quite tricky, because the INews was "perverted" by adding an extra boolean prop (see callNewsAPI).
  const promisesResult: (ApiResponse<INews> & { [key in storyType]?: boolean })[] = await Promise.all(promises)
  // console.log('promisesResult.filter((item) => item?.isSpotLightStories)', promisesResult.filter((item) => item?.isSpotLightStories))
  // Translating from plain and boring IDs into full rich "Stories/News" objects
  // Spotlight Manual mode
  if (Spotlight.object) {
    Spotlight.object.fields.spotlightedStoriesIdsWithAPIResults =
      promisesResult.filter((item) => item?.isSpotLightStories)
  }

  // Infocus
  if (InFocus.object) {
    InFocus.object.fields.storiesWithAPIResults = promisesResult.filter((item) => item?.isInFocusStories)
    InFocus.object.fields.APIResults =
      replaceCommaSeparatedWithFullAPIResults(InFocus.object.fields.closureStories, promisesResult)
  }

  // The Art Market
  if (TheArtMarket.object) {
    TheArtMarket.object.fields.artMarketStoriesWithAPIResults = promisesResult.filter((item) => item?.isTheArtMarket)
  }

  if (LatestStories.object) {
    LatestStories.object.fields.latestStoriesWithAPIResults = promisesResult.filter((item) => item?.isLatestStories)
  }

  // MostPopular
  if (MostPopular.object) {
    MostPopular.object.fields.APIResults = promisesResult.filter((item) => item?.isMostPopularStories)
  }

  if (InPartnershipwith.object) {
    InPartnershipwith.object.fields.APIResults = promisesResult.filter((item) => item?.isInPartnershipwith)
  }

  // Recommended for you
  if (RecommendedForYou.object) {
    RecommendedForYou.object.fields.recommendedForYouStoriesWithResults =
      promisesResult.filter((item) => item?.isRecommendedForYouStories)
  }

  // Highlighted Category Carousel
  if (HighlightedCategoryCarousel.object) {
    HighlightedCategoryCarousel.object.fields.highlightedCategoryResult =
      promisesResult.filter((item) => item?.isHighlightedCategoryCarousel)
  }

  // Highlighted Category listing
  if (HighlightedCategoryListing.object) {
    HighlightedCategoryListing.object.fields.highlightedCategoryResult =
      promisesResult.filter((item) => item?.isHighlightedCategoryListing)
  }

  // Discover More
  if (DiscoverMore.object) {
    DiscoverMore.object.fields.storiesWithAPIResults0 =
      promisesResult.filter((item) => item?.isDiscoverBlockStories0)
    DiscoverMore.object.fields.storiesWithAPIResults1 =
      promisesResult.filter((item) => item?.isDiscoverBlockStories1)
    DiscoverMore.object.fields.storiesWithAPIResults2 =
      promisesResult.filter((item) => item?.isDiscoverBlockStories2)
  }

  // Highlighted Story
  if (HighlightedStory.object) {
    HighlightedStory.object.fields.storiesWithAPIResults =
      replaceCommaSeparatedWithFullAPIResults(HighlightedStory.object.fields.stories, promisesResult)
  }

  // Series Compilations
  if (SeriesCompilations.object) {
    SeriesCompilations.object.fields.storiesWithAPIResults =
      promisesResult.filter((item) => item?.isSeriesCompilationsBlock)
  }

  // Highlighted Story Card
  if (HighlightedStoryCard.object) {
    HighlightedStoryCard.object.fields.storiesWithAPIResults =
      replaceCommaSeparatedWithFullAPIResults(HighlightedStoryCard.object.fields.stories, promisesResult)
  }

  // objectCopy has been adding stuff by reference. Could be confusing but it's how Javascript works.
  const pageMetaInfo = {
    openGraphData: { title: '' },
    type: urlContentData.data.type,
    formerData: objectCopy
  }
  return pageMetaInfo
}

export default contenthubMetaInfo
