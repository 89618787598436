import HeadMeta from './_cms/HeadMeta'
import CMSModuleList from '@components/_cms/CMSModuleList'
import PageNotFound from '../components/Error/PageNotFound'
import JsonLD from '../SEO/jsonLD'
import NextErrorPage from '../pages/_error'
import type IAppProps from 'types/IAppProps'
import ActionModuleLoader from '@components/_cms/ActionModuleLoader'

import { ReactNode } from 'react'
import Contenthub from './Contenthub'
import useTrackingsForChubAndStories from './Contenthub/hooks/useTrackingsForChubAndStories'
import useClickTracking from '@hooks/useClickTracking'
import useCountPrintsTracking from '@hooks/useCountPrintsTracking'

// Comes from app/src/pages/index.tsx
export const CmsPage = ({
  componentCmsData, pageData, query, staticData
}: IAppProps & { children?: ReactNode }) => {
  const siteHostname = staticData?.host || (typeof window !== 'undefined' && window?.location?.hostname)
  const { type } = pageData
  const isPageNotFound = [
    'unavailableArtwork',
    'unavailableRoom',
    'unavailableSpecialRoom',
    'notFoundContent',
    'unavailableCollection'
  ].includes(type)
  const isServerError = !pageData?.type

  useTrackingsForChubAndStories({
    pageType: pageData?.type,
    referer: staticData?.referer,
    category: pageData?.formerData?.category,
    entityId: pageData?.id
  })

  // Used to count clicks after elements are added certain data attributes
  useClickTracking()
  // Used to count how many times an item is in the screen
  useCountPrintsTracking()
  // Server or Backend failure
  // DevOps will return a static HTML error page hosted in /mch_gateway/container/usr/local/etc/haproxy/errors/artbasel
  // FE fallback for the same 50x errors under /pages/_error.js

  if (isServerError) {
    return (
      <>
        <div className='site-content'>
          <NextErrorPage />
        </div>
      </>
    )
  } else if (isPageNotFound) {
    // Where we handle 404 scenarios related to e.g. OVR, Catalog, Events, Exhibitions, Collections
    return (
      <>
        <HeadMeta {...pageData} host={siteHostname} query={query?.slug || '/'} />
        <div className='site-content'>
          {/** @ts-ignore */}
          <PageNotFound pageData={pageData} />
        </div>
      </>
    )
  } else {
    // http 200 status code - we can render all modules returned in Landing Content API response
    return (
      <>
        <HeadMeta {...pageData} host={siteHostname} query={query?.slug || '/'} />
        <JsonLD query={query?.slug || '/'} host={siteHostname} pageMetaInfo={pageData} docType={type} />
        <div className='site-content'>
          {pageData.type === 'contentHubPage' ?
            <Contenthub data={pageData} /> :
            <CMSModuleList components={componentCmsData} />
          }
          <ActionModuleLoader />
        </div>
      </>
    )
  }
}
