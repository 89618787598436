import CustomLinkTag from '@components/CustomLinkTag'
import { cloudinary } from '@constants'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { EditorialCard, Container, Row, Carousel } from '@mch-group/uikit-components'
import { PageContext } from '@providers/PageContext'
import { useContext } from 'react'
import useContenthubContext from '../context'
import { formatArticleLink } from '@utils'

const CHSeriesCompilation = () => {
  const { state } = useContenthubContext()
  const { page: { siteLanguage } } = useContext(PageContext)
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'Series compilation')
  const storiesWithAPIResults = currentBlockData?.fields?.storiesWithAPIResults?.[0]?.data?.items || []

  const labels = useLabelsFromAPI(['News'], siteLanguage)
  return (
    currentBlockData && storiesWithAPIResults?.length ?
      <Container className='px-5 px-lg-8 px-md-7 mb-2 mb-lg-3 pt-5' id='seriescompilation'>
        <Row className='justify-content-center mt-0'>
          <Carousel
            title={currentBlockData?.fields.title}
            editorialTitle={true}
            showCounter={true}
            showTopBorders={false}
            showBottomBorders={false}
            exceedTrack={false}
            //@ts-ignore
            cssStyles={{ marginTop: 0, paddingTop: 0 }}
          >
            {storiesWithAPIResults?.map(story => (
              story &&
              <Carousel.Slide key={story.id}>
                <EditorialCard
                  linkRenderer={(link, children, linkTitle, ariaLabel) => (
                    <CustomLinkTag
                      url={link}
                      ariaLabel={ariaLabel}
                      title={linkTitle}
                      data-mch-tracking={true}
                      data-mch-tracking-action-click={44}
                      data-mch-tracking-action-screenprint={40}
                      data-mch-tracking-measure={story?.id}
                      data-mch-tracking-measure2='seriescompilation'
                      >
                      {children}
                    </CustomLinkTag>
                  )}
                  layout='vertical'
                  link={story?.redirectUrl
                    ? story.redirectUrl : formatArticleLink(story?.url)}
                  size='large'
                  language={undefined}
                  textColour='black'
                  heading={story.title}
                  a11yData={{
                    ariaLabel: story.title,
                    imageAlt: story.title
                  }}
                  ctaLabel={story?.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                  description={story.description}
                  urlImage={`${cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_800,q_auto,f_auto/${story.imageUrl}`)}`}
                />
              </Carousel.Slide>
            ))}
          </Carousel>
        </Row>
      </Container>: null)
}

export default CHSeriesCompilation