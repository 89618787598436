import { FC } from 'react'
import { withRouter } from 'next/router'
import { ErrorPage } from '@mch-group/uikit-components'

interface IPageNotFoundView {
  title?: string
  subtitle?: string
  ctaLabel?: string
  ctaLink?: string
  router: any
  statusCode?: number
}

const PageNotFoundView: FC<IPageNotFoundView> = ({ title, subtitle, ctaLabel, ctaLink, router, statusCode }) => (
  <ErrorPage
    //@ts-ignore
    title={title}
    //@ts-ignore
    subtitle={subtitle}
    //@ts-ignore
    status={statusCode}
    button={{
      text: ctaLabel,
      action: () => {
        if (typeof window !== 'undefined') {
          router.push({ pathname: '/' }, ctaLink)
        }
      }
    }}
  />
)

export default withRouter(PageNotFoundView)
