import { isGalleryStatus } from '@utils'
import { Cities as CitiesService } from '@services'
import sanitizeHtml from 'sanitize-html'
import getProperty from '../getProperty'

const galleryMetaInfo = async ({ urlContentData }) => {
  const galleryData = getProperty(urlContentData, 'data.values.gallery')
  let pageMetaInfo = {}
  const code = galleryData.status
  //@ts-ignore
  pageMetaInfo.statusCode = code
   //@ts-ignore
  pageMetaInfo.status = {
    live: isGalleryStatus.live(code),
    alumni: isGalleryStatus.alumni(code),
    testing: isGalleryStatus.testing(code),
    outOfBusiness: isGalleryStatus.outOfBusiness(code),
    active: isGalleryStatus.active(code),
    passive: isGalleryStatus.passive(code)
  }

  // get the list of cities based on lat / lng coordinates
  if (galleryData.addresses) {
    const coordinatesArray = galleryData.addresses
      .filter(address => (address.isPrimary || address.isGallerySpace) && address.latitude && address.longitude)
      .map(address =>
        CitiesService.getClosest({
          latitude: address.latitude,
          longitude: address.longitude
        }))
    const resultCoordinates = await Promise.all(coordinatesArray)
    const coordinatesNameArray = resultCoordinates
      .map(coordinates => (coordinates.ok ? coordinates.data.name : ''))
      .filter((item, pos, self) =>
        // removing empty string and duplicates
        item !== '' && self.indexOf(item) === pos)
    //@ts-ignore
    pageMetaInfo.coordinatesArray = coordinatesNameArray
  }

  pageMetaInfo = {
    ...galleryData,
    ...pageMetaInfo,
    openGraphData: {
      title: galleryData.name || 'Gallery Profile',
      description: sanitizeHtml(galleryData.description || ''),
      imageUrl: galleryData.imageUrl,
      url: urlContentData.uri
    },
    type: urlContentData.data.type
  }
  return pageMetaInfo
}

export default galleryMetaInfo
