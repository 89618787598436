import CustomLinkTag from '@components/CustomLinkTag'
import { cloudinary } from '@constants'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { Col, Container, EditorialBanner, Row } from '@mch-group/uikit-components'
import { PageContext } from '@providers/PageContext'
import { Media, MediaContextProvider } from '@responsive'
import acceptTargetingCookies from '@utils/acceptTargetingCookies'
import isTargetingCookiesTypeAccepted from '@utils/isTargetingCookiesAccepted'
import isValidYouTubeUrl from '@utils/isValidYouTubeUrl'
import videoRegex from '@utils/isVideoRegex'
import React, { useContext } from 'react'
import useContenthubContext from '../context'

const CHVideo = () => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const { state } = useContenthubContext()
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'Video Module')
  const mediaType = currentBlockData?.fields?.selectedMediaType === '1' ? 'image' : 'video'
  const labels = useLabelsFromAPI(['AltTags', 'YoutubeCookieModal'], siteLanguage)
  // If URL is already a video, don't transform it to m3u8
  const urlPlaceholderVideo = videoRegex.test(currentBlockData?.fields?.mediaUrlDesktop) ?
    currentBlockData?.fields.mediaUrlDesktop : cloudinary.throughCloudfront(
      `https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/video/upload/sp_auto/${currentBlockData?.fields?.mediaUrlDesktop}.m3u8`
    )
  const isYoutubeUrl = isValidYouTubeUrl(currentBlockData?.fields.videoUrl)
  const noCookiesModalProp: { noCookiesModal: IEditorialBanner['noCookiesModal'] } = {
    noCookiesModal: isYoutubeUrl ? {
      heading: labels?.labels?.YoutubeCookieModal?.modalTitle || '',
      description: labels?.labels?.YoutubeCookieModal?.modalDescription || '',
      ctaLabel: labels?.labels?.YoutubeCookieModal?.acceptAndWatchBtnLabel || '',
      isModalNeeded: (setCookiesAccepted) => {
        setCookiesAccepted(isTargetingCookiesTypeAccepted()) // <- aixo d'aqui és on tu has de posar la teva funcio
      },
      onClickAcceptCookies: () => {
        acceptTargetingCookies()
      }
    } : undefined
  }

  return (
    currentBlockData ?
      <MediaContextProvider>
        <Media greaterThanOrEqual='xxl'>
          <div className='mb-8 justify-content-center mt-0' id='video'>
            <Col className='mt-0'>
              <EditorialBanner
                {...noCookiesModalProp}
                urlVideo={currentBlockData.fields.videoUrl}
                urlPlaceholderVideo={mediaType === 'video' ? urlPlaceholderVideo : ''}
                urlPlaceholderImage={mediaType === 'image'
                  ? cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_800,q_auto/${currentBlockData?.fields?.mediaUrlDesktop}`) : ''}
                heading={currentBlockData.fields.title}
                ctaLabel={currentBlockData.fields.ctaLabel || 'Watch Now'}
                eyebrow={currentBlockData.fields.category}
                placeholderType={mediaType}
                mediaType='video'
                headingStyle='default'
                ctaAriaLabel={`${labels?.labels?.AltTags?.genericViewStory} ${currentBlockData?.fields?.title}`}
                linkRenderer={(link, children) =>
                  <CustomLinkTag
                    url={link}
                    data-mch-tracking={true}
                    data-mch-tracking-action-click={44}
                    data-mch-tracking-action-screenprint={40}
                  >{children}
                  </CustomLinkTag>
                }
              />
            </Col>
          </div>
        </Media>
        <Media between={['md', 'xxl']}>
          <Container className='px-5 px-lg-8 px-md-7 mb-8' id='video'>
            <Row className='justify-content-center mt-0'>
              <Col className='mt-0'>
                <EditorialBanner
                  {...noCookiesModalProp}
                  urlVideo={currentBlockData?.fields.videoUrl}
                  urlPlaceholderVideo={mediaType === 'video' ? urlPlaceholderVideo : ''}
                  urlPlaceholderImage={mediaType === 'image'
                    ? cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_800,q_auto/${currentBlockData.fields.mediaUrlDesktop}`) : ''}
                  heading={currentBlockData.fields.title}
                  ctaLabel={currentBlockData.fields.ctaLabel || 'Watch Now'}
                  eyebrow={currentBlockData.fields.category}
                  placeholderType={mediaType}
                  mediaType='video'
                  headingStyle='default'
                  ctaAriaLabel={`${labels?.labels?.AltTags?.genericViewStory} ${currentBlockData.fields.title}`}
                  linkRenderer={(link, children) =>
                    <CustomLinkTag
                      url={link}
                      data-mch-tracking={true}
                      data-mch-tracking-action-click={44}
                      data-mch-tracking-action-screenprint={40}
                    >{children}
                    </CustomLinkTag>
                  }
                />
              </Col>
            </Row>
          </Container>
        </Media>
        <Media lessThan='md'>
          <Container className='px-5 px-lg-8 px-md-7 mb-8' id='video'>
            <Row className='justify-content-center mt-0'>
              <Col className='mt-0'>
                <EditorialBanner
                  urlVideo={currentBlockData?.fields.videoUrl}
                  urlPlaceholderVideo={mediaType === 'video' ? currentBlockData?.fields.mediaUrlMobile : ''}
                  urlPlaceholderImage={mediaType === 'image'
                    ? cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_800,q_auto/${currentBlockData.fields.mediaUrlMobile}`) : ''}
                  heading={currentBlockData.fields.title}
                  ctaLabel={currentBlockData.fields.ctaLabel}
                  eyebrow={currentBlockData.fields.category}
                  placeholderType={mediaType}
                  mediaType='video'
                  headingStyle='default'
                  ctaAriaLabel={`${labels?.labels?.AltTags?.genericViewStory} ${currentBlockData.fields.title}`}
                  linkRenderer={(link, children) =>
                    <CustomLinkTag
                      url={link}
                      data-mch-tracking={true}
                      data-mch-tracking-action-click={44}
                      data-mch-tracking-action-screenprint={40}
                    >{children}
                    </CustomLinkTag>
                  }
                />
              </Col>
            </Row >
          </Container >
        </Media>
      </MediaContextProvider >
      : null)
}

// Some TS magic to obtain the typings of the EditorialBanner component
type IEditorialBanner = React.ComponentProps<typeof EditorialBanner>

export default CHVideo
