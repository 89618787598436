import React, { FC } from 'react'
import styled from 'styled-components'
import { Carousel, StoryCard, Container, Row, Col } from '@mch-group/uikit-components'
import { detectDpr, formatArticleLink, formatDate } from '@utils'
import { cloudinary, date } from '@constants'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { CustomLinkTag } from '@components'

interface IStoriesCarousel {
  stories: ABTypes.Monolith.NewSearchResult[]
  viewAllLabel: string
  moduleId: string
  moduleTitle?: string
}

/**
 * Stories Suggestion Component
 * @returns {JSX.Element} StoriesCarousel
 */
const StoriesCarousel: FC<IStoriesCarousel> = ({ stories, viewAllLabel, moduleId, moduleTitle }) => (
  <section className='stories-carousel mb-9 mb-md-10 mb-md-12' id={moduleId}>
    <Container className='px-5 px-lg-8 px-md-7 mt-0'>
      <Row gutter='g-5 g-md-6 g-xl-7 mb-0'>
        <Col className='mb-3 mb-md-0 mt-0 pt-0'>
          <StyledStories>
            {/* @ts-ignore */}
            <Carousel title={moduleTitle} showTopBorders={false} showBottomBorders={false}>
              {/* @ts-ignore */}
              {stories.map((story, index) => (
                <Carousel.Slide key={index}>
                  <StoryCard
                    image={
                      story.imageUrl &&
                      uploadTransformationUrl(
                        story.imageUrl,
                        `w_326,q_auto,${detectDpr()},ar_1.6,c_crop,c_fill,g_auto`,
                        { transformUrl: cloudinary.throughCloudfront }
                      )
                    }
                    eyebrow={story.authorName || 'Art Basel'}
                    title={story.title || ''}
                    subtitle={
                      story.publishedDate ? formatDate(story.publishedDate, 'YYYY-MM-DD', date.FE_WEEK_DATE_FORMAT) : ''
                    }
                    alt={story.title || ''}
                    linkTitle={story.title || ''}
                    //@ts-ignore
                    description={story.description}
                    storyLink={formatArticleLink(story.url)}
                    linkRenderer={(link, linkTitle, children) => (
                      <CustomLinkTag
                        title={linkTitle}
                        url={story.redirectUrl ? story.redirectUrl : formatArticleLink(story.url)}
                      >
                        {children}
                      </CustomLinkTag>
                    )}
                    button={{
                      type: 'textlink',
                      label: viewAllLabel || 'View All'
                    }}
                    responsive={false}
                    equalHeight
                    inCarousel
                    isHtml={false}
                  />
                </Carousel.Slide>
              ))}
            </Carousel>
          </StyledStories>
        </Col>
      </Row>
    </Container>
  </section>
)

const StyledStories = styled.div`
  .carousel-container {
    padding-top: 0;
    padding-bottom: 0 !important;
  }
`

export default StoriesCarousel
