import { logger } from '@utils'
import { News } from '@services'
import type INews from 'types/INews'
import { ApiResponse } from 'apisauce'

/**
 * Calls the common API call to get Stories/News
 */
const callNewsAPI = async (
  params: Paths.SearchUsingGET.QueryParameters,
  storyType: storyType): Promise<ApiResponse<INews>> => {
  try {
    const result = await News.search(params)
    result[storyType] = true // Assuming this logic is common for all calls
    return result
  } catch (e) {
    logger(e)
    return e
  }
}

export type storyType =
  'isRecommendedForYouStories' |
  'isMostPopularStories' |
  'isHighlightedCategoryListing' |
  'isDiscoverBlockStories0' |
  'isDiscoverBlockStories1' |
  'isDiscoverBlockStories2' |
  'isSeriesCompilationsBlock' |
  'isTheArtMarket' |
  'isInFocusStories' |
  'isInPartnershipwith' |
  'isLatestStories' |
  'isHighlightedCategoryCarousel' |
  'isSpotLightStories'

export default callNewsAPI