import { useCallback, useEffect, useMemo } from 'react'
import useContenthubContext from '../context'
import { getSiteLanguage } from '@utils'
import { News, Tracking } from '@services'
import { returnObjectAndIndexFromNameAndList } from '@utils/contenthub'
import isTargetingCookiesTypeAccepted from '@utils/isTargetingCookiesAccepted'
import { parseDOMForTrackingClicks } from '@hooks/useClickTracking'

/**
 * @description Custom hook to get list of events from API and save it in IndexedDB
 * @returns {Object} { getList }
 * @example
 * const { getList } = useGetList()
 */
const useStoriesListing = () => {
  const { state: { ctxParams, ctxList, ctxLoadMore, contentHub, ctxLoaded }, dispatch }: any = useContenthubContext()
  const prepareResponse = useCallback(
    (responseItems) => {
      if (!ctxList || (ctxList && ctxParams.offset === 0)) {
        return responseItems
      }
      if (ctxLoadMore) {
        return {
          ...responseItems,
          items: [...ctxList.items, ...responseItems.items]
        }
      }
      return {
        ...responseItems,
        items: [...ctxList.items, ...responseItems.items]
      }
    },
    [ctxList, ctxLoadMore, ctxParams]
  )

  const HighlightedStory = returnObjectAndIndexFromNameAndList('Highlighted story (full width)', contentHub?.formerData.modules)
  const HighlightedStoriesId = HighlightedStory?.object?.fields?.stories?.split(',')
  const LatestStories = returnObjectAndIndexFromNameAndList('Category | latest stories', contentHub?.formerData.modules)
  const LatestStoriesId = LatestStories?.object?.fields?.latestStoriesWithAPIResults[0]?.data?.items?.slice(0, 4)?.map(
    (item: any) => item.id
  )
  const SeriesCompilations = returnObjectAndIndexFromNameAndList('Series compilation', contentHub?.formerData.modules)
  const SeriesCompilationsId = SeriesCompilations?.object?.fields?.stories?.split(',')

  const HighlightedStoryCard = returnObjectAndIndexFromNameAndList('Highlighted story (Card)', contentHub?.formerData.modules)
  const HighlightedStoryCardId = HighlightedStoryCard?.object?.fields?.stories.split(',')
  // Filter out blank strings and concatenate
  const allStoriesIds = useMemo(() => {
    const arraysToConcatenate: string[] = []

    if (HighlightedStoriesId !== undefined) {
      arraysToConcatenate.push(...HighlightedStoriesId)
    }

    if (LatestStoriesId !== undefined) {
      arraysToConcatenate.push(...LatestStoriesId)
    }

    if (SeriesCompilationsId !== undefined) {
      arraysToConcatenate.push(...SeriesCompilationsId)
    }

    if (HighlightedStoryCardId !== undefined) {
      arraysToConcatenate.push(...HighlightedStoryCardId)
    }

    return arraysToConcatenate
  }, [HighlightedStoriesId, LatestStoriesId, SeriesCompilationsId, HighlightedStoryCardId])

  // "Stories Listing" block
  const defaultParams = useMemo(() => ({
    type: 'NEWS_ARTICLE',
    limit: 20,
    orderBy: 'ORDER_BY_PUBLISHDATE',
    locale: getSiteLanguage(),
    offset: 0,
    magazineCategoryIds: contentHub?.formerData?.category.id,
    excludedIds: allStoriesIds.join(',')
  }), [allStoriesIds, contentHub])

  useEffect(() => {
    if (!ctxParams && contentHub?.formerData) {
      dispatch({ type: 'SET_PARAMS', payload: defaultParams })
    }
  }, [ctxParams, dispatch, defaultParams, contentHub])

  const getList = useCallback(async () => {
    try {
      const req = await News.search(ctxParams)
      if (req?.ok && req?.data?.items) {
        dispatch({ type: 'SET_LIST', payload: prepareResponse(req.data) })
        dispatch({ type: 'SET_LOADED', payload: true })
        if (isTargetingCookiesTypeAccepted()) {
          Tracking.trackMultipleActions(40, { itemIds: req?.data?.items.map((item) => item.id).map(String) })
          window.removeEventListener('click', parseDOMForTrackingClicks)
          window.addEventListener('click', parseDOMForTrackingClicks)
        }
      }
    } catch (error) {
      console.error(error)
    }
    // FINISHING...
    dispatch({ type: 'SET_LOAD_MORE', payload: false })
  }, [ctxParams, dispatch, prepareResponse])

  const showMore = () => {
    const newParams = {
      ...ctxParams,
      offset: ctxParams.offset === 0 ? 4 : ctxList.offset + 20,
      paginateToken: ctxList.pageToken
    }
    dispatch({ type: 'SET_PARAMS', payload: newParams })
    dispatch({ type: 'SET_LOAD_MORE', payload: true })
  }

  useEffect(() => {
    if (ctxParams && ctxList && ctxParams?.offset !== ctxList?.offset && ctxLoadMore && ctxLoaded) {
      getList()
    }
  }, [ctxList, ctxLoadMore, ctxLoaded, ctxParams, dispatch, getList])

  useEffect(() => {
    if (!ctxList && ctxParams) {
      getList()
    }
  }, [ctxList, ctxParams, defaultParams, getList])

  return {
    getList,
    showMore
  }
}

export default useStoriesListing
