import { createGlobalStyle } from 'styled-components'


const CHArtmarketStyles = createGlobalStyle`
  .module-container {
    display: flex;
    flex-direction: column;
    margin: 16px 0 56px 0;
    @media (min-width: ${global.uikitVars.breakpointMd}) {
        flex-direction: row;
    }
    @media (min-width: ${global.uikitVars.breakpointLg}) {
        margin: 16px 0 64px 0;
    }
    @media (min-width: ${global.uikitVars.breakpointXl}) {
        margin: 16px 0 80px 0;
    }
  }

  .heading-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 32px;
    
    @media (min-width: ${global.uikitVars.breakpointMd}) {
      padding-bottom: 0;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  .firstCardColumn {
    @media (max-width: ${global.uikitVars.breakpointMd}) {
      padding-bottom: 32px;
    }
  }

  .first-card {
    padding-bottom: 32px;
    @media (min-width: ${global.uikitVars.breakpointMd}){
      padding-bottom: 40px;
    }
  }
`

export default CHArtmarketStyles