import { useSelector } from 'react-redux'

import cmsModuleComponents from './_moduleMap'
import ErrorBoundary from '../ErrorBoundary'

/** This Component is in charge of loading the Popups that are indicated in the ?action query param */
/** Blast from the past, we have better ways to do this now, with Labels & Messages */
const ActionModuleLoader = () => {
  const { shared } = useSelector((state) => state)
  const { moduleId, moduleName } = shared.actionModuleData || {}
  const cmsModule = cmsModuleComponents[moduleName] || null
  if (!cmsModule || !shared.actionModuleData) return null

  const { Component } = cmsModule

  return (
    <ErrorBoundary key={`err-${moduleId}-${moduleName}`}>
      <Component key={`${moduleName}-${moduleId}`} moduleName={moduleName} id={moduleId} />
    </ErrorBoundary>
  )
}

export default ActionModuleLoader
