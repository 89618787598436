import mediaTypes from '@constants/mediaTypes'
import React from 'react'
import useContenthubContext from '../context'
import BannerInternal from './common/BannerInternal'

const CHBannerInternal2 = () => {
  const { state } = useContenthubContext()
  const bannerName = 'banner2'
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'Banners Internal (Second Position)')
  const measure = state.contentHub.formerData?.category?.name || 'Landing Page'
  return (
    currentBlockData ?
      <BannerInternal
        url={currentBlockData.fields?.url}
        assetDesktop={currentBlockData.fields.assetDesktop}
        assetTablet={currentBlockData.fields.assetTablet}
        assetMobile={currentBlockData.fields.assetMobile}
        selectedMediaType={currentBlockData?.fields?.selectedMediaType || mediaTypes.image}
        bannerName={bannerName}
        measure={measure}
      />
      : null
  )
}


export default CHBannerInternal2