import { User as UserService } from '@services'

function useNewsletter() {
  const updateSubscription = (setFormSubmitted, setIsSubmitting) => {
    setIsSubmitting(true)

    /**
     * Update the user's newsletter subscription status
     * by calling the UserService.updateUserPreferences method.
     */
    UserService.updateUserPreferences({ newsletterSubscribed: true })
      .then((response) => {
        if (response.status === 200 && response.data) {
          // changes the view to success
          setFormSubmitted(true)
          // start loading spinner
          setIsSubmitting(false)
        }
      })
      .catch(() => {
        setFormSubmitted(false)
        // end loading spinner
        setIsSubmitting(false)
      })
  }

  return { updateSubscription }
}

export default useNewsletter