import getProperty from '../getProperty'

const storyMetaInfo = async ({ urlContentData }) => {
  const storyData = getProperty(urlContentData, 'data.values.article')
  let pageMetaInfo = {
    openGraphData: { title: '' }
  }

  pageMetaInfo = {
    ...pageMetaInfo,
    ...storyData,
    type: urlContentData.data.type
  }
  return pageMetaInfo
}

export default storyMetaInfo
