import CustomLinkTag from '@components/CustomLinkTag'
import { cloudinary } from '@constants'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { EditorialCard, Container, Row, Carousel } from '@mch-group/uikit-components'
import useContenthubContext from '../context'
import { PageContext } from '@providers/PageContext'
import { useContext } from 'react'
import { formatArticleLink } from '@utils'

const CHMostPopular = () => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const { state } = useContenthubContext()
  const labels = useLabelsFromAPI(['ContentHub', 'News'], siteLanguage)
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'Most Popular')
  const MostPopularStories = currentBlockData?.fields?.APIResults?.[0].data?.items
  return (
    currentBlockData && MostPopularStories?.length > 3 ?
      <Container className='px-5 px-lg-8 px-md-7 mb-0 mb-md-4 mb-lg-4 pb-md-2 pb-lg-1 mb-xl-7 pt-5 mt-5' id='mostpopular'>
        <Row className='justify-content-center mt-0'>
          <Carousel
            // @ts-ignore
            title={labels?.labels?.ContentHub?.mostPopularTitle || 'The Most Popular'}
            editorialTitle={true}
            showCounter={true}
            showTopBorders={false}
            showBottomBorders={false}
            exceedTrack={false}
            //@ts-ignore
            cssStyles={{ marginTop: 0, paddingTop: '0' }}
          >
            {MostPopularStories?.slice(0, 5)?.map(story => (
              story &&
              <Carousel.Slide key={story.id}>
                <EditorialCard
                  linkRenderer={(link, children) => (
                    <CustomLinkTag
                      url={link}
                      data-mch-tracking={true}
                      data-mch-tracking-action-click={44}
                      data-mch-tracking-action-screenprint={40}
                      data-mch-tracking-measure={story?.id}
                      data-mch-tracking-measure2='mostpopular'
                    >
                      {children}
                    </CustomLinkTag>
                  )}
                  layout='vertical'
                  link={story?.redirectUrl
                    ? story.redirectUrl : formatArticleLink(story?.url)}
                  size='large'
                  language={undefined}
                  textColour='black'
                  heading={story.title}
                  a11yData={{
                    ariaLabel: story.title,
                    imageAlt: story.title
                  }}
                  ctaLabel={story?.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                  description={story.description}
                  urlImage={`${cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_800,q_auto,f_auto/${story.imageUrl}`)}`}
                />
              </Carousel.Slide>
            ))}
          </Carousel>
        </Row>
      </Container> : null)
}

export default CHMostPopular