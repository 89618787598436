import { Tracking } from '@services'
import isTargetingCookiesTypeAccepted from '@utils/isTargetingCookiesAccepted'
import { useEffect, useCallback } from 'react'

/* This hook can be used for simple view trackings. It takes care of "Cookies accepted or not" */
const useTrackingUniqueview = (action: number | null, id: string | null): void => {
  const trackAction = useCallback(() => {
    if (id && action) {
      isTargetingCookiesTypeAccepted() && Tracking.trackAction(action, id)
    }
  }, [id, action])

  useEffect(() => {
    if (id) {
      trackAction()
    }
  }, [trackAction, id])

}

export default useTrackingUniqueview