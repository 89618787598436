import trackedActions from '@constants/trackedActions'
import prepareStringForTracking from '@utils/prepareStringForTracking'
import { useRouter } from 'next/router'
import useTrackingScrollDownInPercentage from './useTrackingScrollDownInPercentage'
import useTrackingReferer from './useTrackingReferer'
import useTrackingSpaceScrolledDown from './useTrackingSpaceScrolledDown'
import useTrackingTimeinPage from './useTrackingTimeinPage'
import useTrackingUniqueview from './useTrackingUniqueview'

const useAllTrackingsStory = ({
  pageType, referer, category, entityId
}: IUseAllTrackingsStoryParams) => {
  const router = useRouter()

  let idDependingOnPageType: string | null = null
  let actionNumberUniqueViews = 0
  let actionNumberTimeSpent = 0
  let actionNumberScrolldown = 0
  let actionNumberScrolldownMaximum = 0
  let actionNumberReferer = 0
  let scrollInPercentage = false

  if (pageType === 'story') {
    idDependingOnPageType = entityId
    actionNumberUniqueViews = trackedActions.STORYINDIVIDUALVIEW
    actionNumberTimeSpent = trackedActions.STORYTIMESPENTINPAGE
    actionNumberScrolldown = trackedActions.STORYSCROLLINPERCENTAGE
    actionNumberScrolldownMaximum = trackedActions.STORYSCROLLMAXIMUM
    actionNumberReferer = trackedActions.STORYREFERER
    scrollInPercentage = true
  } else if (pageType === 'contentHubPage' && category) {
    idDependingOnPageType = prepareStringForTracking(router.asPath)
    actionNumberUniqueViews = trackedActions.CATEGORYPAGEPAGEINDIVIDUALVIEW
    actionNumberTimeSpent = trackedActions.CATEGORYPAGETIMESPENTINPAGE
    actionNumberScrolldown = trackedActions.CATEGORYPAGESCROLLDOWNINPIXELS
  } else if (pageType === 'contentHubPage' && !category) {
    idDependingOnPageType = 'overviewpage'
    actionNumberUniqueViews = trackedActions.OVERVIEWPAGEINDIVIDUALVIEW
    actionNumberTimeSpent = trackedActions.OVERVIEWTIMESPENTINPAGE
    actionNumberScrolldown = trackedActions.OVERVIEWSCROLLDOWNINPIXELS
  }
  // Hooks cannot be called conditionally.
  // We just call the hook, and if idDependingOnPageType is null, the hook will manage inside and avoid the track
  useTrackingUniqueview(actionNumberUniqueViews, idDependingOnPageType)
  useTrackingTimeinPage(actionNumberTimeSpent, idDependingOnPageType)
  useTrackingSpaceScrolledDown(actionNumberScrolldown, idDependingOnPageType, scrollInPercentage)
  useTrackingScrollDownInPercentage(actionNumberScrolldownMaximum, idDependingOnPageType, pageType)
  useTrackingReferer(actionNumberReferer, idDependingOnPageType, referer)
}

interface IUseAllTrackingsStoryParams {
  pageType: 'story' | 'contentHubPage', // to make global
  referer: string | undefined,
  category: string,
  entityId: string
}

export default useAllTrackingsStory