import React from 'react'
import { Container, Row, Col, Icon } from '@mch-group/uikit-components'
import styled from 'styled-components'
import CustomLinkTag from '@components/CustomLinkTag'
import dayjs from 'dayjs'
import { Media, MediaContextProvider } from '@responsive'

import useHighlightedStoryCard from './CHSpotlight/hooks/useHighligtedStoryCard'

const CHHighligtedStoryCard = () => {
 const { currentBlockData, bannerImageDesktop, bannerImageMobile, labels, storyUrl,
  dateFEFormat } = useHighlightedStoryCard()

  return (
    currentBlockData ?
      <MediaContextProvider>
        <Container className='px-5 px-md-7 ps-lg-8 pe-lg-0 mb-8 mb-lg-14 pt-5' id='highlightedstory-card'>
          <CustomLinkTagStyled url={storyUrl}
            ariaLabel={`${labels?.labels?.AltTags?.genericViewStory} ${currentBlockData?.fields?.storiesWithAPIResults[0]?.title}`}
            data-mch-tracking={true}
            data-mch-tracking-action-click={44}
            data-mch-tracking-action-screenprint={40}
            data-mch-tracking-measure={currentBlockData?.fields?.storiesWithAPIResults[0]?.id}
            data-mch-tracking-measure2='highlightedstory-card'
           >
            <StyledRow className='mt-0'>
              <Col sm={12} md={6} lg={5} xl={4} className='d-flex flex-column mt-0 py-0'>
                <div>
                  <h3 className='editorial-heading-h3 mb-4 mb-lg-5'>{currentBlockData?.fields?.storiesWithAPIResults[0]?.title}</h3>
                  {currentBlockData?.fields?.storiesWithAPIResults[0]?.authorName
                    && currentBlockData?.fields?.storiesWithAPIResults[0]?.publishedDate &&
                    <span className='text-label-small font-color-light-base-700'
                    >
                      {currentBlockData?.fields?.storiesWithAPIResults[0]?.authorName} | {dayjs
                        (currentBlockData?.fields?.storiesWithAPIResults[0]?.publishedDate, 'DD/MM/YYYY HH:mm').format(dateFEFormat)?.replace('.', '')}
                    </span>
                  }
                  {currentBlockData?.fields?.storiesWithAPIResults[0]?.authorName
                    && !currentBlockData?.fields?.storiesWithAPIResults[0]?.publishedDate &&
                    <span className='text-label-small font-color-light-base-700'
                    >
                      {currentBlockData?.fields?.storiesWithAPIResults[0]?.authorName}
                    </span>
                  }
                  {currentBlockData?.fields?.storiesWithAPIResults[0]?.publishedDate
                    && !currentBlockData?.fields?.storiesWithAPIResults[0]?.authorName &&
                    <span className='text-label-small font-color-light-base-700'
                    >
                      {dayjs(currentBlockData?.fields?.storiesWithAPIResults[0]?.publishedDate, 'DD/MM/YYYY HH:mm').format(dateFEFormat)?.replace('.', '')}
                    </span>
                  }
                  <Media greaterThanOrEqual='md'>
                    <Description
                      className='text-medium mt-5 mt-lg-7 font-color-light-base-700 mb-0'
                    >
                      {currentBlockData?.fields?.storiesWithAPIResults[0]?.description}
                    </Description>
                  </Media>
                </div>
                <span className='text-link mt-5 mt-lg-7'>
                  {currentBlockData?.fields?.storiesWithAPIResults[0]?.contentHubCallToAction ||
                  labels?.labels?.News?.ReadMoreCTA}
                  <Icon name='arrow-right' className='ms-3' />
                </span>
              </Col>
              <Col sm={12} md={6} className='mt-7 mt-md-0 offset-lg-1 offset-xl-2 py-0 pe-lg-0 pe-xxl-10'>
                <Media greaterThanOrEqual='md'>
                  <div
                    className='desktop-container'
                  >
                    <img
                      loading='lazy'
                      className='w-100 h-100'
                      src={bannerImageDesktop}
                      alt={`${currentBlockData?.fields?.storiesWithAPIResults[0]?.title} by ${currentBlockData?.fields?.storiesWithAPIResults[0]?.authorName}`}
                    />
                  </div>
                </Media>
                <Media lessThan='md'>
                  <div
                    className='mobile-container'
                  >
                    <img
                      className='w-100 h-100'
                      src={bannerImageMobile}
                      alt={`${currentBlockData?.fields?.storiesWithAPIResults[0]?.title} by ${currentBlockData?.fields?.storiesWithAPIResults[0]?.authorName}`}
                    />
                  </div>
                </Media>
              </Col>
            </StyledRow>
          </CustomLinkTagStyled>
        </Container>
      </MediaContextProvider>
      : null
  )
}

const StyledRow = styled(Row)`
  @media (min-width: 768px) {
    height: 400px;
  }

  @media (min-width: 1024px) {
    height: 500px;
  }

  .desktop-container {
    @media (min-width: ${global.uikitVars.breakpointMd}) and (max-width: ${global.uikitVars.breakpointMdMax}) {
      height: 400px;
    }

    @media (min-width: ${global.uikitVars.breakpointLg}) {
      height: 500px;
    }

    img {
      object-fit: cover;
    }
  }

  .mobile-container {
    height: 360px;

    img {
      object-fit: cover;
    }
  }
`

const CustomLinkTagStyled = styled(CustomLinkTag)`
  &:hover {
    h3,
    .text-link,
    button {
      text-decoration: underline;
    }
  }
`

const Description = styled.p`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export default CHHighligtedStoryCard