import { FC } from 'react'
import type { IContenthubData } from 'types/IContenthubData'
import List1 from './List1'
import List2 from './List2'

const Contenthub: FC<IContenthubData> = ({ data }) => {
  if (data.formerData?.category) {
    return (
      // Category Pages
      <List2 data={data} />
    )
  }
  // Magazine
  return <List1 data={data} />
}

export default Contenthub