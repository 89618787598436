import { createContext, useContext } from 'react'
import type { TPageTypes } from 'types/IAppProps'

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CONTENT_HUB':
      return {
        ...state,
        contentHub: action.payload
      }
    case 'RESET_STORIESLISTING_LIST':
      return {
        ...state,
        ctxList: null,
        ctxLoaded: false,
        ctxInitScroll: false,
        ctxLoadMore: false,
        ctxParams: null
      }
    case 'SET_LIST':
      return {
        ...state,
        ctxList: action.payload
      }
    case 'SET_LOADED':
      return {
        ...state,
        ctxLoaded: action.payload
      }
    case 'SET_INIT_SCROLL':
      return {
        ...state,
        ctxInitScroll: action.payload
      }
    case 'SET_LOAD_MORE':
      return {
        ...state,
        ctxLoadMore: action.payload
      }
    case 'SET_PARAMS':
      return {
        ...state,
        ctxParams: action.payload
      }

    default:
      return { ...state }
  }
}

export const initialState = {
  state: {
    contentHub: null,
    ctxList: null,
    ctxLoaded: false,
    ctxLoadMore: false,
    ctxInitScroll: false,
    ctxParams: null
  },
  refs: {
    // saveCurrentJSON: null
  },
  dispatch: () => null
}

type IStateContext = {
  state: {
    contentHub: {
      formerData: {
        modules: Array<{
          id: any
          name:
          'Banners Internal (Second Position)' |
          'Banners Internal (First Position)' |
          'Banners Internal (Third Position)' |
          'Banners Internal (Fourth Position)' |
          'Today\'s Spotlight' |
          'Partners Banners (First Position)' |
          'Series compilation' |
          'Video Module' |
          'Highlighted Category(Carousel)' |
          'Discover More(Multiple categories)' |
          'Highlighted Category(Listing)' |
          'The Art Market' |
          'Recommended for you' |
          'Partners Banners (Forth Position)' |
          'Partners Banners (Second Position)' |
          'Partners Banners (Third Position)' |
          'In Partnership with' |
          'Most Popular' |
          'In Focus: Fair' |
          'Highlighted story (Card)' |
          'Highlighted story (full width)'
          fields: {
            url: string | undefined
            viewAllUrl: string
            viewAllLabel: any
            pageTitle: string
            dblClickScript: any
            uploadMediaUrlDesktop0: any
            uploadMediaUrlMobile0: any
            storiesWithAPIResults: any
            assetTablet: string | undefined
            assetMobile: string | undefined
            videoUrl: string | undefined
            mediaUrlDesktop: any
            title: any
            ctaLabel: any
            category: string | undefined
            mediaUrlMobile: string | undefined
            assetDesktop: string,
            uploadMediaType: any,
            selectedMediaType: '0' | '1',
            selectedDesktopStoryThumbnail0: any,
            selectedMobileStoryThumbnail0: any,
            spotlightedStoriesIdsWithAPIResults: any,
            spotlightedStoriesIds: any,
            selectedMode: any,
            selectedMobileStoryThumbnail: any,
            title0: any,
            title1: any,
            title2: any,
            title3: any,
            uploadMediaUrlMobile: any,
            uploadMediaUrlDesktop: any,
            eyebrow: any,
            pageTitleAutomaticMode: any,
            artMarketStoriesWithAPIResults: any,
            selectedDesktopStoryThumbnail: any,
            recommendedForYouStoriesWithResults: any,
            APIResults: any,
            latestStoriesWithAPIResults: any,
            shortTitle: any,
            ctaURL: any,
            imageUrl: any,
            selectedCategoryAutoMode: any,
            highlightedCategoryResult: any,
            selectedCategory: any,
          }
        }>
        category: {
          name: string,
          id: number
        }
      },
      type: TPageTypes,
    },
    ctxList: any,
    ctxLoaded: boolean,
    ctxInitScroll: boolean,
    ctxLoadMore: boolean,
    ctxParams: any,
  }
}

// @ts-ignore --> TODO
export const StateContext = createContext<IStateContext>(initialState)

export const useContenthubContext = () => useContext(StateContext)

export default useContenthubContext