import React, { useContext } from 'react'
import { SplideSlide } from '@splidejs/react-splide'
import useTranslatedCategory from '@components/Contenthub/hooks/useTranslatedCategory'
import { PageContext } from '@providers/PageContext'
import insertLineBreaks from '@utils/insertLineBreaks'

const CategoryTitle = ({ selectedCategory }) => {
  const { translatedCategory } = useTranslatedCategory(selectedCategory?.value)
  const { page: { siteLanguage } } = useContext(PageContext)

  const categroy = (siteLanguage === 'en' ? selectedCategory?.label : translatedCategory?.[0]?.value) || selectedCategory?.label

  return (
    <SplideSlide>
      <h4 className={'text-center editorial-heading-h4'} style={{ whiteSpace: 'pre-line' }}>
        {categroy.length >= 20 ? insertLineBreaks(categroy) : categroy}
      </h4>
    </SplideSlide>
  )
}

export default CategoryTitle
