import { cloudinary } from '@constants'
import mediaTypes from '@constants/mediaTypes'
import { Container, Row, VideoPlayer } from '@mch-group/uikit-components'
import { Media, MediaContextProvider } from '@utils/createMedia'
import { FC } from 'react'

const BannerInternal: FC<IBannerInternal> =
  ({ url, assetDesktop, assetTablet, assetMobile, onClick, selectedMediaType, bannerName, measure }) => {
    // Avoid the repetition for tracking for each mediaquery, same code in all of them
    const trackingProps = {
      'data-mch-tracking': true,
      'data-mch-tracking-action-click': 42,
      'data-mch-tracking-action-screenprint': 43,
      'data-mch-tracking-measure': bannerName,
      'data-mch-tracking-measure2': measure
    }
    return (
      <Container className='px-5 px-lg-8 px-md-7 mb-12'>
        <Row className='justify-content-center mt-0'>
          <div
            className='d-flex justify-content-center'
          >
            <MediaContextProvider>
              <Media greaterThanOrEqual='lg'>
                <a href={url} onClick={onClick} target='_blank'>
                  {selectedMediaType === mediaTypes.video &&
                    <div
                      {...trackingProps}
                    >
                      <div className='pe-none'>
                        <VideoPlayer
                          url={cloudinary.throughCloudfront(
                            `https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/video/upload/sp_auto/${assetDesktop}.m3u8`)}
                          width={970}
                          height={95}
                          muted
                          playing
                          playsinline
                          loop
                        />
                      </div>
                    </div>
                  }
                  {selectedMediaType == mediaTypes.image &&
                    <img
                      loading='lazy'
                      src={cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_970,q_auto,f_auto/${assetDesktop}`)}
                      alt='Banner'
                      width={970}
                      {...trackingProps}
                    />
                  }
                </a>
              </Media>
              <Media between={['md', 'lg']}>
                <a href={url} onClick={onClick} target='_blank'>
                  {selectedMediaType === mediaTypes.video &&
                    <div
                      {...trackingProps}
                    >
                      <div className='pe-none'>
                        <VideoPlayer
                          url={cloudinary.throughCloudfront(
                            `https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/video/upload/sp_auto/${assetTablet}.m3u8`)}
                          width={728}
                          height={90}
                          muted
                          playing
                          loop
                          playsinline
                        />
                      </div>
                    </div>}
                  {selectedMediaType == mediaTypes.image &&
                    <img
                      loading='lazy'
                      src={cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_728,q_auto,f_auto/${assetTablet}`)}
                      alt='Banner'
                      width={728}
                      {...trackingProps}
                    />
                  }
                </a>
              </Media>
              <Media lessThan='md'>
                <a href={url} onClick={onClick} target='_blank'>
                  {selectedMediaType === mediaTypes.video &&
                    <div
                      {...trackingProps}
                    >
                      <div className='pe-none'>
                        <VideoPlayer
                          url={cloudinary.throughCloudfront(
                            `https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/video/upload/sp_auto/${assetMobile}.m3u8`)}
                          width={300}
                          muted
                          playing
                          playsinline
                          loop
                        />
                      </div>
                    </div>
                  }
                  {selectedMediaType == mediaTypes.image &&
                    <img
                      loading='lazy'
                      src={cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_300,q_auto,f_auto/${assetMobile}`)}
                      alt='Banner'
                      width={300}
                      {...trackingProps}
                    />
                  }
                </a>
              </Media>
            </MediaContextProvider>
          </div>
        </Row>
      </Container>
    )
  }

interface IBannerInternal {
  measure: string | undefined,
  url: string | undefined,
  assetDesktop: string | undefined,
  assetTablet: string | undefined,
  assetMobile: string | undefined,
  onClick?: () => void,
  bannerName?: string,
  selectedMediaType: '0' | '1' // 0 Image, 1 Video
}

export default BannerInternal