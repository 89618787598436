import React from 'react'
import { EditorialCard, Container, Row, Col } from '@mch-group/uikit-components'
import CustomLinkTag from '@components/CustomLinkTag'
import { formatArticleLink } from '@utils'
import { cloudinary } from '@constants'
import dayjs from 'dayjs'

import useLatestStories from './CHSpotlight/hooks/useLatestStories'
import GlobalStyle from './common/styles/CHCommonLayoutStyles'

const CHLatestStories = (props) => {
  const { siteLanguage, currentBlockData, latestStoriesData, labels, dateFEFormat } = useLatestStories(props)
  return (
    currentBlockData ?
      <Container className='px-5 px-lg-8 px-md-7 mb-11 mb-lg-12 mb-xl-14' id='lateststories'>
        <Row className='justify-content-center mt-0'>
          <Col className='mt-0'>
            <div className='stories-wrapper mb-0 mt-5'>
              <h2 className='heading'>{labels?.labels?.ContentHub?.latestStoriesTitle || 'Latest Stories'}</h2>
              <div className='stories-container'>
                {latestStoriesData?.items?.slice(0, 4)?.map((stories, i) => (
                  <div className={i === 0 ? 'large-card' : `small-card${i}`} key={stories?.id}>
                    <EditorialCard
                      imgLoading='eager'
                      layout='horizontal'
                      size={i === 0 ? 'large' : 'small'}
                      language={siteLanguage as any}
                      a11yData={{
                        ariaLabel: stories?.title,
                        imageAlt: stories?.title
                      }}
                      link={stories?.redirectUrl
                        ? stories.redirectUrl : formatArticleLink(stories?.url)}
                      urlImage={`${cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/${i === 0 ? 'w_900,q_auto' : 'w_300,q_auto'}/${stories.imageUrl}`)}`}

                      heading={stories?.title}
                      description={stories?.description}
                      date={dayjs(stories?.publishedDate, 'YYYY-MM-DD').format(dateFEFormat)?.replace('.', '')}
                      textColour='black'
                      ctaLabel={stories?.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                      author={stories?.authorName}
                      category={props.moduleName === 'Latest Stories' ? stories?.magazineCategory?.name : ''}
                      linkRenderer={(link, label) => (
                        <CustomLinkTag
                          url={link}
                          data-mch-tracking={true}
                          data-mch-tracking-action-click={44}
                          data-mch-tracking-action-screenprint={40}
                          data-mch-tracking-measure={stories?.id}
                          data-mch-tracking-measure2='lateststories'
                        >
                          {label}
                        </CustomLinkTag>
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <GlobalStyle />
      </Container>
      : null
  )
}

export default CHLatestStories
