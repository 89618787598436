import sanitizeHtml from 'sanitize-html'
import getProperty from '../getProperty'

const artistMetaInfo = async ({ urlContentData }) => {
  const artistData = getProperty(urlContentData, 'data.values.artist')
  let pageMetaInfo = {}

  pageMetaInfo = {
    ...pageMetaInfo,
    ...artistData,
    openGraphData: {
      title: artistData.name || 'Gallery Profile',
      description: sanitizeHtml(artistData.description || ''),
      imageUrl: artistData.imageUrl,
      url: urlContentData.uri
    },
    type: urlContentData.data.type
  }
  return pageMetaInfo
}

export default artistMetaInfo
