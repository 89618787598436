import React, { useContext } from 'react'
import { EditorialCard, Container, Row, TextLink } from '@mch-group/uikit-components'
import CustomLinkTag from '@components/CustomLinkTag'
import useContenthubContext from '../context'
import { formatArticleLink } from '@utils'
import { cloudinary, dateFormatForContentHub } from '@constants'
import dayjs from 'dayjs'
import { PageContext } from '@providers/PageContext'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import useTranslatedCategory from '../hooks/useTranslatedCategory'
import 'dayjs/locale/zh-tw'
import GlobalStyle from './common/styles/CHCommonLayoutStyles'

const CHHighlightedCategoryListing = () => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const labels = useLabelsFromAPI(['News', 'AltTags'], siteLanguage)
  const { state } = useContenthubContext()
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'Highlighted Category(Listing)')
  const HighlightedCategoryData = currentBlockData?.fields?.highlightedCategoryResult?.[0]?.data?.items || []
  dayjs.locale(siteLanguage === 'zh_CN' ? 'zh-tw' : siteLanguage || '')
  const dateFEFormat = dateFormatForContentHub[siteLanguage]
  const selectedCategory = currentBlockData?.fields?.selectedMode === 0
    ? currentBlockData?.fields?.selectedCategory : currentBlockData?.fields?.selectedCategoryAutoMode
  const { translatedCategory } = useTranslatedCategory(selectedCategory?.value)
  return (
    currentBlockData ?
      <Container className='px-5 px-lg-8 px-md-7 mb-8 mb-md-11 mb-lg-12 mb-xl-14 pt-5' id='highlightedcategory-listing'>
        <Row className='justify-content-center pt-5'>
          <>
            <div className='d-flex justify-content-between mt-0'>
              <h2 className='heading'>
                {(siteLanguage === 'en' ? selectedCategory?.label : translatedCategory?.[0]?.value) || selectedCategory?.label}
              </h2>
              <CustomLinkTag url={currentBlockData?.fields?.viewAllUrl}
                ariaLabel={labels?.labels?.AltTags?.genericViewAll}>
                <TextLink useLink={true} icon='arrow-right' iconAlign='right' >
                  {currentBlockData?.fields?.viewAllLabel}
                </ TextLink>
              </CustomLinkTag>
            </div>
            <div className='stories-container'>
              {HighlightedCategoryData?.map((stories, i) => (
                <div className={i === 0 ? 'large-card' : `small-card${i}`} key={stories?.id}>
                  <EditorialCard
                    imgLoading='lazy'
                    layout='horizontal'
                    size={i === 0 ? 'large' : 'small'}
                    language={siteLanguage as any}
                    a11yData={{
                      ariaLabel: stories?.title,
                      imageAlt: stories?.title
                    }}
                    link={stories?.redirectUrl
                      ? stories.redirectUrl : formatArticleLink(stories?.url)}
                    urlImage={`${cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_${i === 0 ? '1200' : '200'},q_auto,f_auto/${stories.imageUrl}`)}`}
                    heading={stories?.title}
                    description={stories?.description}
                    date={dayjs(stories?.publishedDate, 'YYYY-MM-DD').format(dateFEFormat)?.replace('.', '')}
                    textColour='black'
                    ctaLabel={stories?.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                    author={stories?.authorName}
                    linkRenderer={(link, label, target, ariaLabel) => (
                      <CustomLinkTag
                        url={link}
                        ariaLabel={ariaLabel}
                        data-mch-tracking={true}
                        data-mch-tracking-action-click={44}
                        data-mch-tracking-action-screenprint={40}
                        data-mch-tracking-measure={stories?.id}
                        data-mch-tracking-measure2='highlightedcategory-listing'
                      >
                        {label}
                      </CustomLinkTag>
                    )}
                  />
                </div>
              ))}
            </div>
          </>
        </Row>
        <GlobalStyle />
      </Container >
      : null
  )
}

export default CHHighlightedCategoryListing
