import useContenthubContext from '../../../context'

/**
 * @returns This hook returns data but also React Components, to keep parent clean
 */
const useCHSpotlight = () => {
  const { state } = useContenthubContext()
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'Today\'s Spotlight')

  return {
    currentBlockData
  }
}

export default useCHSpotlight