import React, { useContext } from 'react'

import CustomLinkTag from '@components/CustomLinkTag'
import useContenthubContext from '../context'
import { EditorialCard, Container, Row, Carousel } from '@mch-group/uikit-components'
import { cloudinary, dateFormatForContentHub } from '@constants'
import dayjs from 'dayjs'
import { PageContext } from '@providers/PageContext'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import useTranslatedCategory from '../hooks/useTranslatedCategory'
import { formatArticleLink } from '@utils'
import 'dayjs/locale/zh-tw'

const CHHighlightedCategoryCarousel = () => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const { state } = useContenthubContext()
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'Highlighted Category(Carousel)')
  const HighlightedCategoryData = currentBlockData?.fields?.highlightedCategoryResult?.[0]?.data?.items || []
  dayjs.locale(siteLanguage === 'zh_CN' ? 'zh-tw' : siteLanguage || '')
  const dateFEFormat = dateFormatForContentHub[siteLanguage]
  const labels = useLabelsFromAPI(['News'], siteLanguage)
  const selectedCategory = currentBlockData?.fields.selectedMode === 0
    ? currentBlockData?.fields?.selectedCategory : currentBlockData?.fields?.selectedCategoryAutoMode
  const { translatedCategory } = useTranslatedCategory(selectedCategory?.value)

  return (
    currentBlockData && HighlightedCategoryData?.length > 0 ?
      <Container className='px-5 px-lg-8 px-md-7 mb-7 pt-5' id='highlightedcategory-carousel'>
        <Row className='justify-content-center mt-0'>
          <Carousel
            title={(siteLanguage === 'en' ? selectedCategory?.label : translatedCategory?.[0]?.value) || selectedCategory?.label}
            editorialTitle={true}
            showCounter={false}
            showTopBorders={false}
            showBottomBorders={false}
            exceedTrack={false}
            options={{
              textLink: true,
              arrows: 0
            } as any}
            controlButtonLabel={currentBlockData?.fields.viewAllLabel}
            controlButtonLink={currentBlockData?.fields.viewAllUrl}
            linkRenderer={(link, children, linkTitle, ariaLabel) =>
              <CustomLinkTag url={link} ariaLabel={ariaLabel} title={linkTitle}>
                {children}
              </CustomLinkTag>
            }
            //@ts-ignore
            cssStyles={{ marginTop: 0, paddingTop: '0' }}
          >
            {HighlightedCategoryData?.map(story => (
              story &&
              <Carousel.Slide key={story.id}>
                <EditorialCard
                  imgLoading='lazy'
                  linkRenderer={(link, children, linkTitle, ariaLabel) => (
                    <CustomLinkTag
                      url={link}
                      ariaLabel={ariaLabel}
                      title={linkTitle}
                      data-mch-tracking={true}
                      data-mch-tracking-action-click={44}
                      data-mch-tracking-action-screenprint={40}
                      data-mch-tracking-measure={story?.id}
                      data-mch-tracking-measure2='highlightedcategory-carousel'
                    >
                      {children}
                    </CustomLinkTag>
                  )}
                  layout='vertical'
                  link={story?.redirectUrl ? story?.redirectUrl : formatArticleLink(story.url)}
                  size='medium'
                  language={undefined}
                  textColour='black'
                  heading={story.title}
                  a11yData={{
                    ariaLabel: story.title,
                    imageAlt: story.title
                  }}
                  ctaLabel={story?.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                  description={story.description}
                  urlImage={`${cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_800,q_auto,f_auto/${story.imageUrl}`)}`}
                  date={dayjs(story.publishedDate, 'YYYY-MM-DD').format(dateFEFormat)?.replace('.', '')}

                />
              </Carousel.Slide>
            ))}
          </Carousel>
        </Row>
      </Container> : null
  )
}

export default CHHighlightedCategoryCarousel
