import sanitizeHtml from 'sanitize-html'

import { Shows } from '@services'
import getProperty from '../getProperty'

const roomMetaInfo = async ({ urlContentData }) => {
  const roomData = getProperty(urlContentData, 'data.values.room')
  const openingDateTimeId = getProperty(urlContentData, 'data.values.openingDateTimeId')
  let pageMetaInfo = {}
  const expiryDate = null

  // get the shows api
  const getShow = await Shows.getOnlineShow(roomData?.onlineShowId)
  if (getShow?.ok) {
    //@ts-ignore
    pageMetaInfo.showData = getShow.data
  }

  pageMetaInfo = {
    ...pageMetaInfo,
    ...roomData,
    expiryDate,
    openGraphData: {
      title: roomData?.title || '',
      description: sanitizeHtml(roomData?.shortParagraphText || ''),
      imageUrl: roomData?.eventImage || '',
      url: urlContentData?.uri || ''
    },
    type: urlContentData.data.type,
    openingDateTimeId
  }
  return pageMetaInfo
}

export default roomMetaInfo
