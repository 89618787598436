import { Tracking } from '@services'
import isTargetingCookiesTypeAccepted from '@utils/isTargetingCookiesAccepted'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

const useTrackingTimeinPage = (action: number, id: string | number | null): void => {
  const router = useRouter()
  const timestamptWhenEntering = useRef(Date.now())

  useEffect(() => {
    if (id) {
      timestamptWhenEntering.current = Date.now()
    }
  }, [router, id])

  useEffect(() => {
    const handleRouteChange = async () => {
      const timestampWhenLeaving = Date.now()
      const timeSpentInCurrentURL = timestampWhenLeaving - timestamptWhenEntering.current

      if (id) {
        isTargetingCookiesTypeAccepted() &&
          Tracking.trackAction(action, id, { measure: Math.floor(timeSpentInCurrentURL / 1000) })
      }
    }

    if (id) {
      router.events.on('routeChangeStart', handleRouteChange)
    }
    return () => {
      if (id) {
        router.events.off('routeChangeStart', handleRouteChange)
      }
    }
  }, [action, router, id])
}

export default useTrackingTimeinPage