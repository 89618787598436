function isValidYouTubeUrl(url) {
  // Regular expression pattern to match YouTube URLs
  const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})$/

  // Test the URL against the pattern
  return pattern.test(url)
}

// Example usage:
// var url1 = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';
// console.log(isValidYouTubeUrl(url1)); // Output: true

// var url2 = 'https://youtu.be/dQw4w9WgXcQ';
// console.log(isValidYouTubeUrl(url2)); // Output: true

// var url3 = 'https://www.example.com';
// console.log(isValidYouTubeUrl(url3)); // Output: false
export default isValidYouTubeUrl