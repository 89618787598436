import useCHSpotlightDesktop from './hooks/useCHSpotlightDesktop'
import { EditorialStories } from '@mch-group/uikit-components'
import PreloadImages from '@components/reusable-modules/PreloadImage'

const CHSpotlightDesktop = () => {
  const { objectToRenderDesktop, fields, labels } = useCHSpotlightDesktop()
  const AltTagsLabels = labels?.labels?.AltTags
  return (
    objectToRenderDesktop?.length > 0 ?
      <>
        <EditorialStories
          h1={fields?.selectedMode === 0 ? fields?.pageTitle : fields?.pageTitleAutomaticMode}
          a11yData={{
            pauseBtn: AltTagsLabels?.pauseAnimationText,
            playBtn: AltTagsLabels?.playAnimationText,
            previousBtn: AltTagsLabels?.prevStoryText,
            nextBtn: AltTagsLabels?.nextStoryText
          }}
          stories={objectToRenderDesktop}
        />
        {objectToRenderDesktop.map((storyItem) =>
          <PreloadImages
            imgUrl={storyItem.backgroundImage}
            key={storyItem.heading}
          />)}
      </> : null
  )
}

export default CHSpotlightDesktop