import sanitizeHtml from 'sanitize-html'

import { Shows } from '@services'
import getProperty from '../getProperty'

const specialRoomMetaInfo = async ({ urlContentData }) => {
  const roomData = getProperty(urlContentData, 'data.values.specialRoom')
  const openingDateTimeId = getProperty(urlContentData, 'data.values.openingDateTimeId')
  let pageMetaInfo = {}
  const expiryDate = null

  // get the shows api
  const getShow = await Shows.getOnlineShow(roomData?.onlineShowId)
  if (getShow?.ok) {
    //@ts-ignore
    pageMetaInfo.showData = getShow.data
  }

  // get the open graph desription
  // the special room currently doesnt have description, so we will get from the artwork
  const getOpenGraphDescription = () => {
    if (roomData?.artworks && roomData.artworks.length > 0 && roomData.artworks[0].description) {
      const cleanText = sanitizeHtml(roomData.artworks[0].description, { allowedTags: [], allowedAttributes: {} })
      return `${cleanText.substring(0, 65)}...`
    }
    return ''
  }

  pageMetaInfo = {
    ...pageMetaInfo,
    ...roomData,
    expiryDate,
    openGraphData: {
      title: roomData?.title || '',
      description: getOpenGraphDescription(),
      imageUrl: roomData?.eventImage || '',
      url: urlContentData?.uri || ''
    },
    openingDateTimeId,
    type: urlContentData.data.type
  }
  return pageMetaInfo
}

export default specialRoomMetaInfo