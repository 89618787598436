import { useLabelsFromAPI } from '@dmi-mch/hooks'
import useContenthubContext from '../../../context'
import { useContext } from 'react'
import { PageContext } from '@providers/PageContext'
import { cloudinary } from '@constants'
import imageRegex from '@utils/isImageRegex'
import videoRegex from '@utils/isVideoRegex'
import { formatArticleLink } from '@utils'
import LinkForSpotlight from '../common'

/**
 * @returns This hook returns data but also React Components, to keep parent clean
 */
const useCHSpotlightDesktop = () => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const { state } = useContenthubContext()
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'Today\'s Spotlight')
  const labels = useLabelsFromAPI(['News', 'AltTags'], siteLanguage)
  const fields = currentBlockData?.fields

  let spotlightedStoriesIdsWithAPIResults = fields?.spotlightedStoriesIdsWithAPIResults?.[0].data?.items
  const idsToFilter = fields?.spotlightedStoriesIds

  if (fields?.selectedMode === 0) {
    const sortOrder: any = new Map(idsToFilter.map((id, index) => [id, index]))
    spotlightedStoriesIdsWithAPIResults = fields?.spotlightedStoriesIdsWithAPIResults?.[0].data?.items
      .sort((a, b) => sortOrder.get(a.id.toString()) - sortOrder.get(b.id.toString()))
  }

  const selectedDesktopStoryThumbnail = fields?.selectedDesktopStoryThumbnail
  const title = [fields?.title0, fields?.title1, fields?.title2, fields?.title3]
  const uploadMediaUrlDesktop = fields?.uploadMediaUrlDesktop
  const uploadMediaType = fields?.uploadMediaType

  const objectToRenderDesktop = spotlightedStoriesIdsWithAPIResults?.map((story, index) => {
    let bgImage
    let bgVideo = ''
    if (fields?.selectedMode === 0) {
      bgImage = (uploadMediaType?.[index] === 1 &&
        uploadMediaUrlDesktop?.[index]?.length > 0) ?
        uploadMediaUrlDesktop?.[index] : selectedDesktopStoryThumbnail?.[index]

      bgVideo = (uploadMediaType?.[index] === 1 &&
        uploadMediaUrlDesktop?.[index]?.length > 0) ? uploadMediaUrlDesktop?.[index] : ''
    } else {
      bgImage = story.featureImageUrl
    }

    const bgImageDiscardingVideos = imageRegex.test(bgImage) ? bgImage : null

    const backgroundImage = bgImageDiscardingVideos && cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/f_auto,ar_16:9,c_crop,c_fill,g_auto,w_2200,fl_progressive/${bgImage}`)

    let backgroundVideo: any = null
    if (bgVideo !== '') {
      if (videoRegex.test(bgVideo) && !imageRegex.test(bgVideo)) {
        backgroundVideo = bgVideo
      } else if (imageRegex.test(bgVideo)) {
        backgroundVideo = null
      } else {
        backgroundVideo = cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/video/upload/sp_auto/${bgVideo}.m3u8`)
      }
    }

    const storyUrl = story?.redirectUrl ? story.redirectUrl : formatArticleLink(story.url)

    return {
      heading: story.title,
      backgroundImage,
      ctaLabel: story.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA,
      link: storyUrl,
      linkRenderer: (link, children) => (
        <LinkForSpotlight
          url={link}
          data-mch-tracking-measure={story.id}
          storyId={story.id}
        >{children}
        </LinkForSpotlight>),
      eyebrow: fields?.selectedMode === 0 ? title?.[index] : fields?.eyebrow,
      backgroundVideo
    }
  })

  return {
    spotlightedStoriesIdsWithAPIResults,
    selectedDesktopStoryThumbnail,
    title,
    uploadMediaUrlDesktop,
    uploadMediaType,
    fields,
    labels,
    currentBlockData,
    objectToRenderDesktop
  }
}

export default useCHSpotlightDesktop