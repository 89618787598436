import { Tracking } from '@services'
import isTargetingCookiesTypeAccepted from '@utils/isTargetingCookiesAccepted'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

// Tracks any click in the website as long as thºe clicked element has these properties:
// data-mch-tracking={true}
// data-mch-tracking-action-click={39} (39 is example)

const useCountPrintsTracking = () => {
  const router = useRouter()
  const countItems = () => {
    let trackableItems: Array<[string, string]> = []
    const totalTrackedItems = document.querySelectorAll('[data-mch-tracking="true"]')
    totalTrackedItems.forEach((elm) => {
      if (
        elm.hasAttribute('data-mch-tracking-measure') &&
        elm.hasAttribute('data-mch-tracking-action-screenprint')
      ) {
        const screenprint = elm.getAttribute('data-mch-tracking-action-screenprint') || ''
        const measure = elm.getAttribute('data-mch-tracking-measure') || ''
        trackableItems.push([screenprint, measure])
      }
    })
    const grouping = trackableItems.reduce((acc, curr) => {
      const key = curr[0]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(curr[1])
      return acc
    }, {})

    if (isTargetingCookiesTypeAccepted()) {
      Object.keys(grouping).forEach((group) => {
        Tracking.trackMultipleActions(group, { itemIds: grouping[group] })
      })
    }
  }

  // On navigations
  useEffect(() => {
    router.events.on('routeChangeComplete', countItems)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', countItems)
    }
  }, [router.events])

  // First load
  useEffect(() => {
    setTimeout(() => {
      countItems()
    }, 1500)
  }, [router.events])
}

export default useCountPrintsTracking