import { Tracking } from '@services'
import isTargetingCookiesTypeAccepted from '@utils/isTargetingCookiesAccepted'
import { useEffect, useCallback } from 'react'

const useTrackingReferer = (action: number, id: string | null, referer: string | undefined): void => {
  const trackAction = useCallback(() => {
    if (id && action) {
      const params = { measure: referer }
      isTargetingCookiesTypeAccepted() && Tracking.trackAction(action, id, params)
    }
  }, [id, action, referer])

  useEffect(() => {
    if (id) {
      trackAction()
    }
  }, [trackAction, id])

}

export default useTrackingReferer