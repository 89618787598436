// Function that strips the initial slash, if there is one, and also that replaces the rest of slashes with double underscores
const prepareStringForTracking = (path) => {
  let pathCopy = path
  // Remove initial slash, if present
  if (path.startsWith('/')) {
    pathCopy = path.substring(1)
  }

  // Replace slashes with double underscores
  pathCopy = pathCopy.replace(/\//g, '__')

  return pathCopy
}

export default prepareStringForTracking