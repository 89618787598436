import React, { FC, useState } from 'react'
import { Container, Row, Col, Divider } from '@mch-group/uikit-components'
import { Text } from '@components'
import { Button } from '@mch-group/uikit-components'
import { useContext } from 'react'
import styled from 'styled-components'
import { LoginContext } from '@providers/Login'
import useNewsletter from '@modules/Newsletter/useNewsletter'

interface IRoomMapModule {
  id: string
  newsletter_headline?: string
  newsletter_description?: string
  newsletter_subscribe_CTA?: string
  newsletter_subscribe_description?: string
  newsletter_success_description?: string
  isDividerHide?: boolean
}

const RoomMapModule: FC<IRoomMapModule> = ({
  newsletter_headline,
  newsletter_description,
  newsletter_subscribe_CTA,
  newsletter_subscribe_description,
  newsletter_success_description,
  isDividerHide,
  id
}) => {
  const { isUserLogged, user } = useContext(LoginContext)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { updateSubscription } = useNewsletter()

  return isUserLogged && !user?.me?.newsletterSubscribed ? (
    <section id={id} className='newsletter-module pb-9 pb-md-10 pb-md-12 bg-white'>
      <Container className='px-5 px-lg-8 px-md-7 mt-0'>
        <Divider color='mch-gray-200' size='m' className='mb-10 mb-lg-12' />
        <Row>
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 8, offset: 2 }}
            xl={{ span: 8, offset: 2 }}
          >
            {/* Stay on top of the fine art world. */}
            {newsletter_headline && <h3 className='text-center mb-2'>{newsletter_headline}</h3>}
            {/* Subscribe to our newsletter to receive the latest art stories directly to your inbox. */}
            {newsletter_description && <p className='text-medium text-center mb-0'>{newsletter_description}</p>}
          </Col>
          {!formSubmitted && (
            <Col
              xs={{ span: 12, offset: 0 }}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 8, offset: 2 }}
              xl={{ span: 8, offset: 2 }}
              className='mt-8 mt-md-9'
            >
              <StyledCta className='d-flex align-content-center align-items-center justify-content-center'>
                <div className='text-center w-100'>
                  {newsletter_subscribe_CTA && (
                    <Button
                      type='button'
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={() => updateSubscription(setFormSubmitted, setIsSubmitting)}
                    >
                      {/* SUBSCRIBE NOW */}
                      {newsletter_subscribe_CTA}
                    </Button>
                  )}
                </div>
              </StyledCta>
              {newsletter_subscribe_description && (
                <Text isHtml className='mt-4 mt-md-5 desc text-center' forwardedAs='div'>
                  {/* By subscribing, I accept the Art Basel Terms of Use. */}
                  {newsletter_subscribe_description?.replace(/<p>/g, '<p class="text-label-small">')}
                </Text>
              )}
            </Col>
          )}
          {formSubmitted && (
            <Col
              xs={{ span: 12, offset: 0 }}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 8, offset: 2 }}
              xl={{ span: 8, offset: 2 }}
              className='mt-8'
            >
              {newsletter_success_description && (
                <StyledSuccess>
                  <Text isHtml forwardedAs='div' className='text-center'>
                    {/* Thank you for subscribing! You can manage your contact preferences within your profile. */}
                    {newsletter_success_description?.replace(/<p>/g, '<p class="text-small mb-0">')}
                  </Text>
                </StyledSuccess>
              )}
            </Col>
          )}
        </Row>
        {!isDividerHide && <Divider color='mch-gray-200' size='m' className='mt-10 mt-lg-12' />}
      </Container>
    </section>
  ) : null
}

const StyledSuccess = styled.div`
  p:first-child {
    margin-bottom: 32px;
  }
`

const StyledCta = styled.div`
  flex-direction: row;

  .desc {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column;

    button {
      width: 100% !important;
    }

    p {
      text-align: center;
    }
  }
`

export default RoomMapModule
