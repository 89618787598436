import React, { useContext } from 'react'
import { EditorialCard, Container, Row } from '@mch-group/uikit-components'
import CustomLinkTag from '@components/CustomLinkTag'
import useContenthubContext from '../context'
import { formatArticleLink } from '@utils'
import dayjs from 'dayjs'
import { dateFormatForContentHub } from '@constants/date'
import { PageContext } from '@providers/PageContext'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import 'dayjs/locale/zh-tw'
import CHArtmarketStyles from './common/styles/CHArtmarketStyles'

const CHTheArtMarket = () => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const labels = useLabelsFromAPI(['News'], siteLanguage)
  const { state } = useContenthubContext()
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'The Art Market')
  const fields = currentBlockData?.fields
  const artMarketStoriesWithAPIResults = fields?.artMarketStoriesWithAPIResults?.[0]?.data.items
  const firstArtMarketStories = artMarketStoriesWithAPIResults?.[0]

  const secondArtMarketStories = artMarketStoriesWithAPIResults?.[1]

  const thirdArtMarketStories = artMarketStoriesWithAPIResults?.[2]

  const forthArtMarketStories = artMarketStoriesWithAPIResults?.[3]
  dayjs.locale(siteLanguage === 'zh_CN' ? 'zh-tw' : siteLanguage || '')
  const dateFEFormat = dateFormatForContentHub[siteLanguage]

  return (
    currentBlockData ?
      <Container className='px-5 px-lg-8 px-md-7 pt-5' id='artmarket'>
        <Row className='justify-content-center mt-0'>
          <div className='module-container row gx-5 gx-lg-7 gx-xxl-8 mt-0'>
            <div className='heading-section col-12 col-md-4 col-xxl-5 p-0 mt-0'>
              <h2 className='heading mb-8 mb-md-0'>{currentBlockData?.fields.title}</h2>
            </div>
            <div className='col-12 col-md-4 col-lg-3 p-0 mb-sm-8 mb-md-0'>
              {firstArtMarketStories &&
                <div className='first-card'>
                  {/* @ts-ignore */}
                  <EditorialCard
                    imgLoading='lazy'
                    heading={firstArtMarketStories.title}
                    description={firstArtMarketStories.description}
                    date={dayjs(firstArtMarketStories.publishedDate, 'YYYY-MM-DD').format(dateFEFormat)?.replace('.', '')}
                    link={firstArtMarketStories.redirectUrl
                      ? firstArtMarketStories.redirectUrl : formatArticleLink(firstArtMarketStories.url)}
                    ctaLabel={firstArtMarketStories?.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                    linkRenderer={(link, label, target, ariaLabel) => (
                      <CustomLinkTag
                        url={link} ariaLabel={ariaLabel}
                        data-mch-tracking={true}
                        data-mch-tracking-action-click={44}
                        data-mch-tracking-action-screenprint={40}
                        data-mch-tracking-measure={firstArtMarketStories?.id}
                        data-mch-tracking-measure2='artmarket'
                      >
                        {label}
                      </CustomLinkTag>
                    )}
                    {...EditorialCardOptions}
                    language={siteLanguage as any}
                    a11yData={{
                      ariaLabel: firstArtMarketStories.title,
                      imageAlt: firstArtMarketStories.title
                    }}
                  />
                </div>
              }
              {secondArtMarketStories &&
                <div className='second-card'>
                  {/* @ts-ignore */}
                  <EditorialCard
                    {...EditorialCardOptions}
                    imgLoading='lazy'
                    language={siteLanguage as any}
                    heading={secondArtMarketStories.title}
                    description={secondArtMarketStories.description}
                    date={dayjs(secondArtMarketStories.publishedDate, 'YYYY-MM-DD').format(dateFEFormat)?.replace('.', '')}
                    link={secondArtMarketStories.redirectUrl
                      ? secondArtMarketStories.redirectUrl : formatArticleLink(secondArtMarketStories.url)}
                    ctaLabel={secondArtMarketStories?.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                    // urlImage={`${cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_400,q_auto,f_auto/${secondArtMarketStories.imageUrl}`)}`}
                    linkRenderer={(link, label, target, ariaLabel) => (
                      <CustomLinkTag
                        url={link}
                        ariaLabel={ariaLabel}
                        data-mch-tracking={true}
                        data-mch-tracking-action-click={44}
                        data-mch-tracking-action-screenprint={40}
                        data-mch-tracking-measure={secondArtMarketStories?.id}
                      >
                        {label}
                      </CustomLinkTag>
                    )}
                    a11yData={{
                      ariaLabel: secondArtMarketStories.title,
                      imageAlt: secondArtMarketStories.title
                    }}
                  />
                </div>
              }
            </div>
            <div className='col-12 col-md-4 col-lg-3 offset-lg-1 p-0'>
              {thirdArtMarketStories &&
                <div className='first-card'>
                  {/* @ts-ignore */}
                  <EditorialCard {...EditorialCardOptions}
                    heading={thirdArtMarketStories.title}
                    description={thirdArtMarketStories.description}
                    date={dayjs(thirdArtMarketStories.publishedDate, 'YYYY-MM-DD').format(dateFEFormat).replace('.', '')}
                    link={thirdArtMarketStories.redirectUrl
                      ? thirdArtMarketStories.redirectUrl : formatArticleLink(thirdArtMarketStories.url)}
                    ctaLabel={thirdArtMarketStories?.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                    linkRenderer={(link, label, target, ariaLabel) => (
                      <CustomLinkTag
                        url={link}
                        ariaLabel={ariaLabel}
                        data-mch-tracking={true}
                        data-mch-tracking-action-click={44}
                        data-mch-tracking-action-screenprint={40}
                        data-mch-tracking-measure={thirdArtMarketStories?.id}
                      >
                        {label}
                      </CustomLinkTag>
                    )}
                    a11yData={{
                      ariaLabel: thirdArtMarketStories.title,
                      imageAlt: thirdArtMarketStories.title
                    }}
                  />
                </div>
              }
              {forthArtMarketStories &&
                <div className='second-card'>
                  {/* @ts-ignore */}
                  <EditorialCard {...EditorialCardOptions}
                    heading={forthArtMarketStories.title}
                    description={forthArtMarketStories.description}
                    date={dayjs(forthArtMarketStories.publishedDate, 'YYYY-MM-DD').format(dateFEFormat)?.replace('.', '')}
                    link={forthArtMarketStories.redirectUrl
                      ? forthArtMarketStories.redirectUrl : formatArticleLink(forthArtMarketStories.url)}
                    ctaLabel={forthArtMarketStories?.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                    linkRenderer={(link, label, target, ariaLabel) => (
                      <CustomLinkTag
                        url={link}
                        ariaLabel={ariaLabel}
                        data-mch-tracking={true}
                        data-mch-tracking-action-click={44}
                        data-mch-tracking-action-screenprint={40}
                        data-mch-tracking-measure={forthArtMarketStories?.id}
                      >
                        {label}
                      </CustomLinkTag>
                    )}
                    a11yData={{
                      ariaLabel: forthArtMarketStories.title,
                      imageAlt: forthArtMarketStories.title
                    }}
                  />
                </div>
              }
            </div>
          </div>
        </Row>
        <CHArtmarketStyles />
      </Container>
      : null
  )
}

const EditorialCardOptions = {
  layout: 'vertical',
  size: 'small',
  textColour: 'black'
}

export default CHTheArtMarket
