import { Container } from '@mch-group/uikit-components'
import ContenthubProvider from '../provider'
import CHHighlightedStory from '../views/CHHighlightedStory'
import CHLatestStories from '../views/CHLatestStories'

import CHPartners2 from '../views/CHPartners2'
import CHPartners1 from '../views/CHPartners1'
import CHSeriesCompilation from '../views/CHSeriesCompilation'
import CHHighligtedStoryCard from '../views/CHHighligtedStoryCard'
import CHStoryListing from '../views/CHStoryListing'
import CHPartners3 from '../views/CHPartners3'
import { memo } from 'react'
import CHVideo from '../views/CHVideo'
import CHBannerInternal1 from '../views/CHBannerInternal1'
import CHBannerInternal2 from '../views/CHBannerInternal2'
import CHBannerInternal3 from '../views/CHBannerInternal3'


const List2 = ({ data }) =>
  <ContenthubProvider url='/contenthub' languagesList={[]} data={data}>
    <Container className='px-5 px-lg-8 px-md-7'>
      <h1 className='heading-display mt-13 mt-md-14 mt-xl-15 mb-9'>{data?.formerData?.category?.name}</h1>
    </Container>
    <CHLatestStories moduleName='Category | latest stories' />
    <CHPartners1 />
    <CHBannerInternal1 />
    <CHHighlightedStory />
    <CHVideo />
    <CHSeriesCompilation />
    <CHPartners2 />
    <CHBannerInternal2 />
    <CHHighligtedStoryCard />
    <CHStoryListing />
    <CHPartners3 />
    <CHBannerInternal3 />
  </ContenthubProvider>


export default memo(List2)