/**
 * This function is used for both server and client params preparation for News Module.
 * It prepares the params that the News API will need.
 */
const getNewsAPIParams = ({
  labels,
  selectedCategory,
  selectedProduct,
  randomStr, firstLimit,
  secondLimit,
  offset,
  paginateToken,
  locale
}) => {
  let orderByParam: Paths.SearchUsingGET.Parameters.OrderBy = 'ORDER_BY_RANDOM'
  // This block of code exists because of a small bug in API, which BE is not fixing for now.
  // Infinite scroll brings really random results, not consintent page 1 with page 2 for example
  // with the result of repeated items.
  // To workaround this, when order is random and layout is infinite scroll, we just force "no random".
  if (labels.news_grp1_option_ordering === 'publishedDate' || labels.news_grp1_article === '3n') {
    orderByParam = 'ORDER_BY_PUBLISHDATE'
  }

  // PREPARING THE CATEGORIES LIST
  const categoryIds = selectedCategory != 0 ? selectedCategory : null

  // PREPARING THE PRODUCTS LIST
  const productId = selectedProduct != 0 ? selectedProduct : null

  // This will contain the list all the Product Ids.
  let productIds: Paths.SearchUsingGET.Parameters.ProductIds = []

  // Pushing the Product to the array if there is any dropdown selection
  productId !== 0 && productIds.push(productId)

  // Pushing the Product to the array if there is any preselection from CMS
  labels.news_grp1_multi_select_product && productIds.push(labels.news_grp1_multi_select_product)

  if (productIds.length === 0) {
    // To avoid sending the field in the API when it's undefined
    // @ts-ignore --> Code unconsistency, productIds CANNOT NOT BE UNDEFINED, as it's specified in the Types
    productIds = undefined
  } else {
    // @ts-ignore --> Code unconsistency, productIds CANNOT NOT BE A STRING, as it's specified in the Types
    productIds = productIds.join()
  }

  // Extending the object because BE Data model / FE code unconsistencies
  const newsParams: Paths.SearchUsingGET.QueryParameters & { paginateToken: string, locale: string } = {
    orderBy: orderByParam,
    categoryIds,
    productIds,
    offset: offset || 0,
    limit: offset == 0 ? firstLimit : secondLimit,
    // strangely, the BE data model doesn't have paginateToken, but the API needs it.
    paginateToken: paginateToken || null,
    tagIds: labels.news_grp1_multi_select_tag as unknown as number[],
    cityIds: labels.news_grp1_multi_select_city as unknown as number[],
    type: labels.news_grp1_option_article_type,
    locale,
    randomStr
  }
  if (labels.news_grp1_multi_select_excluded_tags) {
    newsParams.excludedTagIds = labels.news_grp1_multi_select_excluded_tags as unknown as number[]
  }
  return newsParams
}

export default getNewsAPIParams