import { useContext } from 'react'
import useContenthubContext from '../../../context'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { PageContext } from '@providers/PageContext'
import { formatArticleLink } from '@utils'
import { cloudinary, dateFormatForContentHub } from '@constants'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-tw'

/**
 * @returns This hook returns data but also React Components, to keep parent clean
 */
const useHighlightedStoryCard = () => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const { state } = useContenthubContext()
  const labels = useLabelsFromAPI(['News', 'AltTags'], siteLanguage)
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'Highlighted story (Card)')
  const bannerImageDesktop = currentBlockData?.fields?.uploadMediaType === 1
  ? cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/f_auto,ar_16:9,c_crop,c_fill,g_auto,w_800/${currentBlockData?.fields?.uploadMediaUrlDesktop0}`)
  : cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/f_auto,ar_16:9,c_crop,c_fill,g_auto,w_800/${currentBlockData?.fields?.selectedDesktopStoryThumbnail0}`)

  const bannerImageMobile = currentBlockData?.fields?.uploadMediaType === 1
  ? cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/f_auto,ar_16:9,c_crop,c_fill,g_auto,w_800/${currentBlockData?.fields?.uploadMediaUrlMobile0}`)
  : cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/f_auto,ar_16:9,c_crop,c_fill,g_auto,w_800/${currentBlockData?.fields?.selectedMobileStoryThumbnail0}`)

  const storyUrl = currentBlockData?.fields?.storiesWithAPIResults[0]?.redirectUrl
    ? currentBlockData?.fields?.storiesWithAPIResults[0]?.redirectUrl
    : formatArticleLink(currentBlockData?.fields?.storiesWithAPIResults[0]?.url)

  dayjs.locale(siteLanguage === 'zh_CN' ? 'zh-tw' : siteLanguage || '')
  const dateFEFormat = dateFormatForContentHub[siteLanguage]

  return {
    currentBlockData,
    bannerImageDesktop,
    bannerImageMobile,
    labels,
    storyUrl,
    dateFEFormat
  }
}

export default useHighlightedStoryCard