import { FC, useContext, useEffect } from 'react'
import { withRouter } from 'next/router'

import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { getSiteLanguage } from '@utils/storage'
import { LoginContext } from '@providers/Login'
import PageNotFoundView from './PageNotFoundView'
import StoriesCarouselModule from '@modules/StoriesCarousel'

const PageNotFound: FC<IPageNotFound> = ({ pageData, router }) => {
  const { uri, type } = pageData
  const action = router?.query.action
  const { showLogin, isUserLogged, user } = useContext(LoginContext)

  const { labels } = useLabelsFromAPI(['ErrorPageMessages'], getSiteLanguage())

  useEffect(() => {
    if (action && !isUserLogged && !user?.accessToken) {
      showLogin({
        onLoginSuccessCallback: () => {
          router.replace(
            {
              pathname: '/',
              query: router.query
            },
            router.asPath
          )
        },
        openOnlyOnce: true
      })
    }
  }, [action, isUserLogged, router, showLogin, user?.accessToken])

  return typeof labels !== 'undefined' && uri ? (
    <>
      {/*
      https://mch-applicationservices.atlassian.net/browse/AB-195
      HTTP 404 | BE are not able to detect what kind of page user was trying to access */}
      {type === 'notFoundContent' &&
        !uri.includes('catalog/artwork') &&
        !uri.includes('collections/share') &&
        !uri.includes('rooms/detail') &&
        !uri.includes('events/detail') &&
        !uri.includes('catalog/artist') &&
        !uri.includes('rooms/specialsector') && (
          <PageNotFoundView
            statusCode={404}
            title={labels['ErrorPageMessages'].errorPageTitle || 'Sorry, the page you’re looking for does not exist.'}
            subtitle={
              labels['ErrorPageMessages'].errorPageSubtitle ||
              'The page may have been removed or you might have mistyped the address.'
            }
            ctaLabel={labels['ErrorPageMessages'].errorPageCTA || 'Return to homepage'}
            ctaLink={labels['ErrorPageMessages'].errorPageCTALink || '/'}
          />
        )}
      {
        /* https://mch-applicationservices.atlassian.net/browse/AB-371
        HTTP 404 | User share a link from an OVR (artwork, room, collection) who does not have permisson yet. */
        ['unavailableRoom', 'unavailableSpecialRoom', 'unavailableArtwork', 'unavailableCollection'].includes(type) && (
          <>
            <PageNotFoundView
              title={
                labels['ErrorPageMessages'].errorPageContentNotAvailableTitle ||
                'Sorry, this content is not available yet.'
              }
              subtitle={
                labels['ErrorPageMessages'].errorPageContentNotAvailableSubtitle ||
                'General Admission opening date: Fri, Nov 29, 2021'
              }
              ctaLabel={labels['ErrorPageMessages'].errorPageContentNotAvailableCTA || 'Discover OVR stories'}
              ctaLink={labels['ErrorPageMessages'].errorPageContentNotAvailableCTALink || '/ovr'}
            />
            {pageData && <StoriesCarouselModule {...pageData} />}
          </>
        )
      }
      {
        /* https://mch-applicationservices.atlassian.net/browse/AB-372
        HTTP 404 | User access to OVR room has been deleted/archived */
        type === 'notFoundContent' && (uri.includes('rooms/detail') || uri.includes('rooms/specialsector')) && (
          <>
            <PageNotFoundView
              title={
                labels['ErrorPageMessages'].errorPageContentExpiredTitle ||
                'Sorry, this content is no longer available.'
              }
              subtitle={
                labels['ErrorPageMessages'].errorPageContentExpiredSubtitle ||
                'The OVR you are trying to access has been expired.'
              }
              ctaLabel={labels['ErrorPageMessages'].errorPageContentExpiredCTA || 'Discover OVR'}
              ctaLink={labels['ErrorPageMessages'].errorPageContentExpiredCTALink || 'OVR Landing page'}
            />
            {pageData && <StoriesCarouselModule {...pageData} />}
          </>
        )
      }
      {
        /*
        https://mch-applicationservices.atlassian.net/browse/AB-374
        HTTP 404 (Page not found) | User try to access to an artist page which has been deleted
        */
        type === 'notFoundContent' && uri.includes('catalog/artist') && (
          <PageNotFoundView
            title={
              labels['ErrorPageMessages'].errorPageArtistRemovedTitle || 'Sorry, this content is no longer available.'
            }
            subtitle={
              labels['ErrorPageMessages'].errorPageArtistRemovedSubtitle ||
              'The content may have been removed or you might have mistyped the address.'
            }
            ctaLabel={labels['ErrorPageMessages'].errorPageArtistRemovedCTA || 'Discover all artists'}
            ctaLink={labels['ErrorPageMessages'].errorPageArtistRemovedCTALink || '/artists'}
          />
        )
      }
      {
        /*
        https://mch-applicationservices.atlassian.net/browse/AB-373
        HTTP 404 | OVR artwork / collection archived / deleted. Shared artwork/collection archived/deleted.
        */
        type === 'notFoundContent' && (uri.includes('catalog/artwork') || uri.includes('collections/share')) && (
          <>
            <PageNotFoundView
              title={
                labels['ErrorPageMessages'].errorPageContentRemovedTitle ||
                'Sorry, this content is no longer available.'
              }
              subtitle={
                labels['ErrorPageMessages'].errorPageContentRemovedSubtitle ||
                'The content you are trying to access has been removed.'
              }
              ctaLabel={labels['ErrorPageMessages'].errorPageContentRemovedCTA || 'Discover all artworks'}
              ctaLink={labels['ErrorPageMessages'].errorPageContentRemovedCTALink || '/artworks'}
            />
            {pageData && uri.includes('catalog/artwork') && <StoriesCarouselModule {...pageData} />}
          </>
        )
      }
      {
        /*
        https://mch-applicationservices.atlassian.net/browse/AB-375
        HTTP 404 (Page not found) | event/exhibition has been deleted/archived
        */
        type === 'notFoundContent' && uri.includes('events/detail') && (
          <PageNotFoundView
            title={labels['ErrorPageMessages'].errorPageEventExpiredTitle}
            subtitle={labels['ErrorPageMessages'].errorPageEventExpiredSubtitle}
            ctaLabel={labels['ErrorPageMessages'].errorPageEventExpiredCTA}
            ctaLink={labels['ErrorPageMessages'].errorPageEventExpiredCTALink}
          />
        )
      }
    </>
  ) : null
}

interface IPageNotFound {
  type?: any
  uri?: any
  data?: any
  height?: string
  description?: string
  router?: any
  statusCode?: number
  pageData?: any
}

export default withRouter(PageNotFound)
