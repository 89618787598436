import { useCallback, useEffect } from 'react'
import { StoriesCarouselContext } from './StoriesCarouselContext'
import { News } from '@services'
import { getSiteLanguage } from '@utils'
import { useLabelsFromAPI } from '@dmi-mch/hooks'

/**
 * Stories Suggestion Module Hook
 * @description This hook is used to get the stories suggestion module data
 * @returns {object} CMSProps, moduleVisible, moduleTitle, moduleId, moduleLabels, loaded, stories
 */
const useStoriesCarousel = () => {
  const { labels } = useLabelsFromAPI(['ErrorPageMessages'], getSiteLanguage())
  // @ts-ignore
  const [{ CMSProps, loaded, stories, moduleLabels }, dispatch] = StoriesCarouselContext()

  const findValue = useCallback((objKey) => CMSProps?.fieldsList?.find((i) => i.key === objKey)?.values[0].value, [
    CMSProps?.fieldsList
  ])

  const getStoriesCompleted = useCallback(() => {
    dispatch({ type: 'SET_STORIES_LOADED', payload: true })
  }, [dispatch])

  const getStories = useCallback(async () => {
    try {
      let req = await News.search({
        orderBy: findValue('ssc_grp1_sorting_options')?.replace('_DESC', '') || 'ORDER_BY_PUBLISHDATE',
        limit: findValue('ssc_grp1_number_of_items') || 6,
        locale: getSiteLanguage()
      })
      if (req.ok && req?.data) {
        dispatch({ type: 'SET_STORIES', payload: req?.data?.items || [] })
        getStoriesCompleted()
      }
    } catch (error) {
      console.error(error)
      getStoriesCompleted()
    }
  }, [dispatch, findValue, getStoriesCompleted])

  const getLabelsCompleted = useCallback(() => {
    dispatch({ type: 'SET_LABELS', payload: labels })
  }, [dispatch, labels])

  useEffect(() => {
    if (!loaded) {
      getStories()
    }
  }, [getStories, loaded])

  useEffect(() => {
    if (!moduleLabels && labels) {
      getLabelsCompleted()
    }
  }, [getLabelsCompleted, labels, moduleLabels])

  return {
    CMSProps,
    moduleVisible: findValue('ssc_grp1_visibility') === 'on' || false,
    moduleTitle: findValue('ssc_grp1_title') || '',
    moduleId: CMSProps?.moduleId || '',
    moduleLabels,
    loaded,
    stories
  }
}

export default useStoriesCarousel
