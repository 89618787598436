import React, { useContext } from 'react'
import { Container, Row, Col, Button, EditorialCard } from '@mch-group/uikit-components'

import useStoriesListing from '../hooks/useStoriesListing'
import useContenthubContext from '../context'
import CustomLinkTag from '@components/CustomLinkTag'
import { cloudinary, dateFormatForContentHub } from '@constants'
import { Media, MediaContextProvider } from '@responsive'
import dayjs from 'dayjs'
import { PageContext } from '@providers/PageContext'
import { formatArticleLink } from '@utils'
import 'dayjs/locale/zh-tw'

const CHStoryListing = () => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const { state: { ctxList, ctxLoadMore, contentHub } }: any = useContenthubContext()
  const list = ctxList?.items?.length > 0 ? ctxList?.items : []
  const hasMore = ctxList?.hasMore
  const { showMore } = useStoriesListing()
  const currentBlockData = contentHub?.formerData?.modules.find((item) => item.name === 'Stories listing')
  dayjs.locale(siteLanguage === 'zh_CN' ? 'zh-tw' : siteLanguage || '')
  const dateFEFormat = dateFormatForContentHub[siteLanguage]

  //artworks
  return (
    currentBlockData ?
      <MediaContextProvider>
        <Container className={`px-5 px-lg-8 px-md-7 pt-5 ${!hasMore ? 'mb-7 mb-xl-10' : 'mb-0'}`} id='storylisting'>
          {/* LIST OF Stories */}
          <Media greaterThanOrEqual='md'>
            {list.length > 0 ? (
              <Row gutter='g-5 g-md-8 g-lg-11 g-xxl-12 mb-0 pb-0 mt-0 me-0'>
                <div className='text-medium mb-7 mb-md-8 mb-xxl-9 font-color-light-base-1000 mt-0'>More {contentHub?.formerData?.category?.name} stories</div>
                {list?.map((story, i: number) =>
                (
                  <Col md={3} key={i} className='mb-md-8 d-flex mx-0 mt-0 pe-0'>
                    <EditorialCard
                      imgLoading='lazy'
                      inCarousel={false}
                      linkRenderer={(link, children, linkTitle, ariaLabel) => (
                        <CustomLinkTag
                          url={link}
                          ariaLabel={ariaLabel}
                          title={linkTitle}
                          className='w-100'
                          data-mch-tracking={true}
                          data-mch-tracking-action-click={44}
                          data-mch-tracking-action-screenprint={40}
                          data-mch-tracking-measure={story?.id}
                          data-mch-tracking-measure2='storylisting'
                        >
                          {children}
                        </CustomLinkTag>
                      )}
                      layout='vertical'
                      link={story?.redirectUrl
                        ? story.redirectUrl : formatArticleLink(story?.url)}
                      size='medium'
                      language={undefined}
                      textColour='black'
                      heading={story.title}
                      aspectRatio={'1.618/1'}
                      a11yData={{
                        ariaLabel: story.title,
                        imageAlt: story.title
                      }}
                      date={dayjs(story?.publishedDate, 'YYYY-MM-DD').format(dateFEFormat).replace('.', '')}
                      urlImage={`${cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_500,q_auto,f_auto/${story.imageUrl}`)}`}
                    />
                  </Col>
                )
                )}
              </Row>
            ) : null}
          </Media>

          <Media lessThan='md'>
            {list.length > 0 ? (
              <Row gutter='g-5 g-md-7 g-xl-7 mb-0 pb-0 mt-0 me-0'>
                <div className='text-medium mb-7 mb-md-8 mb-xxl-9 font-color-light-base-1000 mt-0'>More {contentHub?.formerData?.category?.name} stories</div>

                {list?.map((story, i: number) =>
                (
                  <Col key={i} sm={12} className='mb-4 d-flex mt-0'>
                    <EditorialCard
                      inCarousel={false}
                      linkRenderer={(link, children, linkTitle, ariaLabel) => (
                        <CustomLinkTag
                          url={link}
                          ariaLabel={ariaLabel}
                          title={linkTitle}
                          className='w-100'
                          data-mch-tracking={true}
                          data-mch-tracking-action-click={44}
                          data-mch-tracking-action-screenprint={40}
                          data-mch-tracking-measure={story?.id}
                        >
                          {children}
                        </CustomLinkTag>
                      )}
                      layout='horizontal'
                      link={`/stories/${story.url}`}
                      size='small'
                      language={siteLanguage as any}
                      textColour='black'
                      heading={story.title}
                      a11yData={{
                        ariaLabel: story.title,
                        imageAlt: story.title
                      }}
                      date={dayjs(story?.publishedDate, 'YYYY-MM-DD').format(dateFEFormat)?.replace('.', '')}
                      author={story?.authorName}
                      urlImage={`${cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_800,q_auto,f_auto/${story.imageUrl}`)}`}
                    />
                  </Col>
                )
                )}
              </Row>
            ) : null}
          </Media>

          {/* SHOW MORE BUTTON */}
          {hasMore && (
            <Row className='g-5 g-md-7 g-xl-7 pt-0 mt-7 mt-md-0 mt-lg-3 mt-xl-5 mb-11 mb-xl-14'>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}
                className='justify-content-center d-flex pt-0 mt-0'>
                <Media lessThan='lg'>
                  <Button variant='secondary' size='compact' onClick={showMore} isLoading={ctxLoadMore} className='mt-0'>
                    Load more
                  </Button>
                </Media>
                <Media greaterThanOrEqual='lg'>
                  <Button variant='secondary' onClick={showMore} isLoading={ctxLoadMore} className='mt-0'>
                    Load more
                  </Button>
                </Media>
              </Col>
            </Row>
          )}
        </Container>
      </MediaContextProvider>
      : null
  )
}

export default CHStoryListing
