import React from 'react'
import { setPageData } from '@stores/page'
import { Response } from 'express'
import { detectSiteLanguage, prepareUAT } from '@utils'
import type IAppProps from 'types/IAppProps'
import { NextPage, NextPageContext } from 'next'
import { CmsPage } from '@components/CmsPage'
import { Languages } from '@services'
import { checkMobileWebview } from '@utils/mobileWebview'
import { DefaultRootState } from 'react-redux'
import { Store } from 'redux'
import { checkIfRedirectToLegacy } from '@utils/checkIfRedirectToLegacy'
import getSiteHostName from '@utils/getSiteHostName'
import doNewsModuleDataPreloadIfNecessary from '@components/reusable-modules/News/ssr'
import type { IStaticData } from 'types/IAppProps'
import getWhatToDoForUrl from '@utils/page/getWhatToDoWithURL'
import doSomethingWithUrl from '@utils/page/doSomethingWithURL'

const Page: NextPage<IAppProps> = (props) => <CmsPage {...props} />

// Our initialProps are a weird thing.
// They contain the (normal) NextProps + (anormal) reduxStore
Page.getInitialProps = async (nextPageContext: NextPageContext & {
  reduxStore: Store<DefaultRootState>
}) => {
  const {
    query,
    res,
    req,
    asPath,
    reduxStore
  } = nextPageContext

  // Start area to fit in the old HOCS refactored to normal functions
  checkIfRedirectToLegacy(asPath, !!res)
  // const siteHostName = getSiteHostName(req)
  await prepareUAT(req) // UAT: UserAccessToken

  // Some data we only want it to load in the first (ssr) call, not next navigations.
  // Is this used at all??? Can be removed maybe? Cannot see where it's used
  const appFirstLoadData: ssrOnlyData = {}
  if (typeof window === 'undefined') {
    const { data } = await Languages.getList('website')
    appFirstLoadData.languagesList = data
    appFirstLoadData.isMobileWebview = checkMobileWebview(req, query)
  }
  const { siteLanguage } = detectSiteLanguage(req)


  // Preparing static SSR data. This data doesn't change through the session
  // Can be consumed in AppProvider, if you look at the Providers in inspector
  const staticData: IStaticData = {}
  if (typeof window === 'undefined') {
    const { data } = await Languages.getList('website')
    staticData.referer = req?.headers.referer
    staticData.languagesList = data
    staticData.isMobileWebview = checkMobileWebview(req, query)
    staticData.host = req?.headers?.host
    const siteHostName = getSiteHostName(req)
    staticData.siteHostName = {
      hostname: siteHostName,
      brand: '',
      variant: 'artbasel',
      logoVariant: 'default',
      url: siteHostName?.includes('localhost') ? 'http://localhost:3000' : `https://${siteHostName}`
    }
  }

  // This object is available to consume in _app.tsx
  const props: IAppProps = {
    query: query || { slug: '/' },
    pageTitle: '',
    componentCmsData: [],
    statusCode: 200,
    pageData: {},
    pageCmsData: {},
    siteLanguage,
    staticData
  }
  const userAgent = req && req.headers['user-agent'] ||
    reduxStore.getState().page.userAgent

  const whatToDoWithUrl = await getWhatToDoForUrl({
    req,
    uri: asPath,
    // Language is available, provided by "withSiteLang".
    language: siteLanguage,
    query
  })

  whatToDoWithUrl && (await doSomethingWithUrl(whatToDoWithUrl, props, res as Response, siteLanguage))

  await doNewsModuleDataPreloadIfNecessary(props.componentCmsData || [], siteLanguage)

  // This is independent from what we pass to the module, we should deprecate this
  reduxStore.dispatch(setPageData({
    componentCmsData: props.componentCmsData,
    pageMetaInfo: {
      openGraphData: { title: '' },
      externalTitle: null
    },
    query: query || { slug: '/' },
    userAgent
  }))
  return props
}

interface ssrOnlyData {
  languagesList?: ABTypes.Monolith.Locale[],
  isMobileWebview?: boolean | null,
  referer?: string | null,
}

export default Page