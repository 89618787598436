/* eslint-disable no-loops/no-loops */
const insertLineBreaks = (text: string) => {
 // Define the threshold or condition where you want to insert line breaks
 const breakThreshold = 24 // for example

 // Split the text into chunks based on the threshold
 let chunks: string[] = []
 for (let i = 0; i < text.length; i += breakThreshold) {
   let chunk = text.substring(i, i + breakThreshold)
   if (i !== 0) { // Add hyphen if not the first chunk
     chunk = `${chunk}`
   }
   chunks.push(chunk)
 }
 // Join the chunks with line breaks
 return chunks.join('-\n')
}

export default insertLineBreaks