/**
 * Accepts the targeting cookies on the OneTrust modal
 */
const acceptTargetingCookies = () => {
  const element: HTMLInputElement | null = document.getElementById('ot-group-id-C0004') as HTMLInputElement
  element.click()
  const oneTrustSaveButton = document.getElementsByClassName('save-preference-btn-handler')[0] as HTMLButtonElement
  oneTrustSaveButton.click()
}

export default acceptTargetingCookies