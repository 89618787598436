function calculateScrollPercentage() {
  const windowHeight = window.innerHeight
  const documentHeight = document.body.clientHeight || document.documentElement.clientHeight
  const scrollTop =
    window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop

  const scrollHeight = documentHeight - windowHeight
  const scrollPercentage = (scrollTop / scrollHeight) * 100

  return scrollPercentage
}

export default calculateScrollPercentage