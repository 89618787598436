import React, { useContext } from 'react'

import CustomLinkTag from '@components/CustomLinkTag'
import useContenthubContext from '../context'

import { EditorialCard, Container, Row, Carousel } from '@mch-group/uikit-components'
import { cloudinary } from '@constants'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { PageContext } from '@providers/PageContext'
import { formatArticleLink } from '@utils'

const CHRecommendedForYou = () => {
  const { state } = useContenthubContext()
  const { page: { siteLanguage } } = useContext(PageContext)
  const labels = useLabelsFromAPI(['News'], siteLanguage)
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'Recommended for you')
  const recommendedForYouData = currentBlockData?.fields?.recommendedForYouStoriesWithResults?.[0]?.data?.items || []
  return (
    currentBlockData && recommendedForYouData?.length > 0 ?
      <Container className='px-5 px-lg-8 px-md-7 mb-md-9 mb-xl-7 pt-5' id='recommendedforyou'>
        <Row className='justify-content-center mt-0'>
          <Carousel
            title={currentBlockData?.fields?.title}
            editorialTitle={true}
            showCounter={true}
            showTopBorders={false}
            showBottomBorders={false}
            exceedTrack={false}
            //@ts-ignore
            cssStyles={{ marginTop: 0, paddingTop: 0 }}
          >
            {recommendedForYouData.map(story => (
              story &&
              <Carousel.Slide key={story.id}>
                <EditorialCard
                  imgLoading='lazy'
                  linkRenderer={(link, children, linkTitle, ariaLabel) => (
                    <CustomLinkTag
                      url={link}
                      ariaLabel={ariaLabel}
                      title={linkTitle}
                      data-mch-tracking={true}
                      data-mch-tracking-action-click={44}
                      data-mch-tracking-action-screenprint={40}
                      data-mch-tracking-measure={story?.id}
                      data-mch-tracking-measure2='recommendedforyou'
                    >
                      {children}
                    </CustomLinkTag>
                  )}
                  layout='vertical'
                  link={story?.redirectUrl
                    ? story.redirectUrl : formatArticleLink(story?.url)}
                  size='large'
                  language={undefined}
                  textColour='black'
                  heading={story.title}
                  a11yData={{
                    ariaLabel: story.title,
                    imageAlt: story.title
                  }}
                  ctaLabel={story?.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                  description={story.description}
                  urlImage={`${cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/w_900,q_auto,f_auto/${story.imageUrl}`)}`}
                />
              </Carousel.Slide>
            ))}
          </Carousel>
        </Row>
      </Container> : null
  )
}

export default CHRecommendedForYou
