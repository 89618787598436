import CookieKeys from '@constants/CookieKeys'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import getCookieExpireDate from './getCookieExpireDate'
import { getCookie } from './storage'

/**
 * This function does too many things: tells if isMobilewebview, sets cookie, etc.
 * It's just bringing the same the old HOC had
 * @param req Type "any". Couldn't not clear this out. ICookieResponse VS IncomingMessage
 * @param query
 * @returns
 */
const checkMobileWebview =
  (req: any, query: NextParsedUrlQuery): boolean | null => {
    if (getCookie(req, CookieKeys.IS_MOBILE_WEBVIEW)) {
      return true
    } else if (req && query && 'isMobileWebview' in query) {
      if (query.isMobileWebview === 'true') {
        req.universalCookies.set(CookieKeys.IS_MOBILE_WEBVIEW, query.isMobileWebview, {
          path: '/',
          expires: getCookieExpireDate(10)
        })
        return true
      } else if (query.isMobileWebview === 'false') {
        req.universalCookies.remove(CookieKeys.IS_MOBILE_WEBVIEW)
        return false
      }
    }
    return false
  }

export { checkMobileWebview }