import { Tracking } from '@services'
import isTargetingCookiesTypeAccepted from '@utils/isTargetingCookiesAccepted'
import { useEffect } from 'react'

// Tracks any click in the website as long as the clicked element has these properties:
// data-mch-tracking={true}
// data-mch-tracking-action-click={39} (39 is example)

export const parseDOMForTrackingClicks = event => {
  if (isTargetingCookiesTypeAccepted()) {
    const clickedElem = event['target']
    const closestElemWithTrackingData = clickedElem.closest('[data-mch-tracking="true"]')
    const dataset = closestElemWithTrackingData?.dataset
    if (dataset && dataset['mchTracking'] === 'true') {
      if (dataset['mchTrackingActionClick']) {
        const action = dataset['mchTrackingActionClick']
        let id = null
        if (dataset['mchTrackingMeasure']) {
          id = dataset['mchTrackingMeasure']
        }
        let measure2 = null
        if (dataset['mchTrackingMeasure2']) {
          measure2 = dataset['mchTrackingMeasure2']
        }
        Tracking.trackAction(action, id, measure2 ? { measure: measure2 } : {}) // In progress
      }
    }
  }
}

const useClickTracking = () => {
  useEffect(() => {
    window.addEventListener('click', parseDOMForTrackingClicks)
    return () => {
      window.removeEventListener('click', parseDOMForTrackingClicks)
    }
  })
}

export default useClickTracking