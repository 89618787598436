import isLegacyPath from './isLegacyPath'

const checkIfRedirectToLegacy = (asPath: string | undefined, hasResponseObject: boolean) => {
  if (isLegacyPath(asPath)) {
    if (!hasResponseObject && asPath) {
      // Force SSR to hit proxy
      // All server-side requests goes through proxy on remote servers
      // In case, the path requested is in list of legacy paths, its not going to be rendered by this app.
      window.location.href = asPath
    }
  }
}

export { checkIfRedirectToLegacy }