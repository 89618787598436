import StoriesCarousel from '@components/StoriesCarousel'
import React, { useReducer, useState, useMemo, FC } from 'react'
import { environment } from '@dmi-mch/utils'
import { initialState, reducer, StateContext } from './StoriesCarouselContext'
import useStoriesCarousel from '@modules/StoriesCarousel/useStoriesCarousel'

interface IStoriesCarouselModule {
  // @ts-ignore
  CMSProps: ABTypes.Monolith.GlobalModule['storiesSuggestionContentModule']
}

const StoriesCarouselInitiator = () => {
  const { loaded, stories, moduleLabels, moduleId, moduleTitle, moduleVisible } = useStoriesCarousel()
  return moduleVisible && loaded ? (
    <StoriesCarousel
      stories={stories}
      viewAllLabel={moduleLabels?.ErrorPageMessages?.storiesSuggestionCTA}
      moduleId={moduleId}
      moduleTitle={moduleTitle}
    />
  ) : null
}

const StoriesCarouselModule: FC<IStoriesCarouselModule> = (props) => {
  const [hydratedInitialState] = useState({
    CMSProps: props,
    moduleLabels: null,
    loaded: null,
    stories: null
  })

  useMemo(() => {
    if (JSON.stringify(Object.keys(hydratedInitialState).sort()) !== JSON.stringify(Object.keys(initialState).sort())) {
      environment.isNotPro && console.error('Initial state vs context doesnt match!')
    }
  }, [hydratedInitialState])

  return (
    // @ts-ignore
    <StateContext.Provider value={useReducer(reducer, hydratedInitialState)}>
      <StoriesCarouselInitiator />
    </StateContext.Provider>
  )
}

export default StoriesCarouselModule
