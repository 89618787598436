import { FC } from 'react'
import styled from 'styled-components'

const PreloadImages: FC<IPreloadImages> = ({ imgUrl }) => {
  if (!imgUrl) return null
  return (
    <Images
      key={imgUrl}
      bgImg={imgUrl}
    />
  )
}

const Images = styled.div<any>`
  width: 0;
  height: 0;
  display: inline;
  ${props => props.bgImg && `background-image: url(${props.bgImg})`}
`

interface IPreloadImages {
  imgUrl: string
}

export default PreloadImages