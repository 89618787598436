import { useContext } from 'react'
import useContenthubContext from '../../../context'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { PageContext } from '@providers/PageContext'
import { dateFormatForContentHub } from '@constants'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-tw' // Import Chinese traditional locale

/**
 * @returns This hook returns data but also React Components, to keep parent clean
 */
const useLatestStories = (props) => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const { state } = useContenthubContext()
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === props.moduleName)
  const latestStoriesData = currentBlockData?.fields?.latestStoriesWithAPIResults?.[0]?.data

  dayjs.locale(siteLanguage === 'zh_CN' ? 'zh-tw' : siteLanguage || '')
  const dateFEFormat = dateFormatForContentHub[siteLanguage]
  const labels = useLabelsFromAPI(['ContentHub', 'News'], siteLanguage)
  return {
    currentBlockData,
    latestStoriesData,
    labels,
    dateFEFormat,
    siteLanguage
  }
}

export default useLatestStories