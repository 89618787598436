import _ from 'lodash'

// Returns full API response objects, given a lame string separated commas list of numbers
export const replaceCommaSeparatedWithFullAPIResults = (stringCommaSeparated, apiResponseObject) => {
  const idsList = stringCommaSeparated?.split(',').map(Number)
  const items = idsList?.length > 0 && idsList.map((item) =>
    apiResponseObject.find((obj) => obj?.data.id === item)?.data as never)
  return items
}

// Returns full API response objects, given an Array of numbers
export const replaceArraydWithFullAPIResults = (arr, apiResponseObject) => {
  const items = _.compact(arr).map((item) =>
    apiResponseObject.find((obj) => obj?.data.id === parseInt(item as string, 0))?.data)
  return items
}

export const returnObjectAndIndexFromNameAndList = (blockName, blocksList): ICHUBObjectAndIndex => {
  const object = blocksList.find((newsItem) => newsItem.name === blockName)
  const index = blocksList.findIndex((newsItem) => newsItem.name === blockName)
  return {
    object,
    index
  }
}

interface ICHUBObjectAndIndex {
  object: {
    fields: {
      storiesIdsBySelectedTag: any
      selectedMode: 0 | 1, // 0 is manual, 1 is automatic
      tags: any
      excludedTags: any
      selectedCategoryAutoMode: {
        0: {
          value: string
        },
        1: {
          value: string
        },
        value: string
      },
      mostViewedNewsIds: any
      spotlightedStoriesIdsWithAPIResults: any
      storiesWithAPIResults: any
      APIResults: any
      artMarketStoriesWithAPIResults: any
      latestStoriesWithAPIResults: any
      recommendedForYouStoriesWithResults: any
      highlightedCategoryResult: any
      storiesWithAPIResults0: any
      storiesWithAPIResults1: any
      storiesWithAPIResults2: any
      stories: any,
      closureStories: any,
      spotlightedStoriesIds: any,
      stories0: any,
      stories1: any,
      stories2: any,
    }
  },
  index: number
}