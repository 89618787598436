import { News } from '@services'
import shortid from 'shortid'
import type IModule from 'types/IModule'
import defineLimit from '../utils/defineLimit'
import getNewsAPIParams from '../utils/getNewsAPIParams'

/**
 * Function that preloads the content for the News Module. If there are 2 News Modules, only works in the 1st
 * @param cmsComponentsList
 */
const doNewsModuleDataPreloadIfNecessary = async (cmsComponentsList: IModule[], siteLanguage) => {
  const newsModule = cmsComponentsList?.findIndex((module) => module.moduleName === 'News')
  // Will only be executed if News module is found in the list
  if (newsModule > -1) {
    const newsLabels = cmsComponentsList[newsModule].componentProps
    const randomStr = shortid.generate()
    const newsParams = getNewsAPIParams({
      labels: cmsComponentsList[newsModule].componentProps,
      selectedCategory: newsLabels.news_grp1_default_category ? newsLabels.news_grp1_default_category : 0,
      selectedProduct: newsLabels.news_grp1_default_product ? newsLabels.news_grp1_default_product : 0,
      randomStr,
      firstLimit: defineLimit(newsLabels.news_grp1_article),
      secondLimit: 27,
      offset: 0,
      paginateToken: null,
      locale: siteLanguage
    })
    const newsResult = await News.search(newsParams)
    cmsComponentsList[newsModule] = {
      ...cmsComponentsList[newsModule],
      ssrData: {
        SSRNewsList: newsResult.data?.items,
        SSRNewsRandomStr: randomStr,
        SSRNewsFirstPagetoken: newsResult.data?.pageToken,
        SSRNewsLayout: newsLabels.news_grp1_article
      }
    }
  }
}

export default doNewsModuleDataPreloadIfNecessary