import { Media, MediaContextProvider } from '@responsive'
import useCHSpotlight from './hooks/useCHSpotlight'
import CHSpotlightDesktop from './CHSpotlightDesktop'
import CHSpotlightMobile from './CHSpotlightMobile'

/** SPOTLIGHT MODULE */
const CHSpotlight = () => {
  const {
    currentBlockData
  } = useCHSpotlight()

  return (
    currentBlockData ?
      <MediaContextProvider>
        <div className='mb-7 mb-lg-8' id='spotlight'>
          <Media lessThan='md'>
            <CHSpotlightMobile />
          </Media>
          <Media greaterThanOrEqual='md'>
            <CHSpotlightDesktop />
          </Media>
        </div>
      </MediaContextProvider> : null)
}

export default CHSpotlight