import sanitizeHtml from 'sanitize-html'
import getProperty from '../getProperty'

const eventMetaInfo = async ({ urlContentData }) => {
  const eventData = getProperty(urlContentData, 'data.values.event')
  const openingDateTimeId = getProperty(urlContentData, 'data.values.openingDateTimeId')
  let pageMetaInfo = {}
  const expiryDate = null

  pageMetaInfo = {
    ...pageMetaInfo,
    ...eventData,
    expiryDate,
    openGraphData: {
      title: eventData.title || 'Event detail',
      description: sanitizeHtml(eventData.shortParagraphText || ''),
      imageUrl: eventData.eventImage,
      url: urlContentData.uri
    },
    openingDateTimeId,
    type: urlContentData.data.type
  }
  return pageMetaInfo
}

export default eventMetaInfo
