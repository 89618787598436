import sanitizeHtml from 'sanitize-html'

import { logger } from '@dmi-mch/utils'
import { Artworks as ArtworksService } from '@services'
import formatArtworkTitle from '@utils/formatArtworkTitle'
import { getSiteLanguage } from '@utils'
import getProperty from '../getProperty'

const artworkMetaInfo = async ({ urlContentData, props }) => {
  const artworkData = getProperty(urlContentData, 'data.values.artwork')
  const eventDataAssociated = getProperty(urlContentData, 'data.values.event')
  let pageMetaInfo = {}

  if (artworkData.showInRoom && props?.query?.comingFromCollection !== 'true') {
    try {
      // this request is used in OVR artworks navigation inside a room
      const req = await ArtworksService.getArtworkDetail({
        galleryId: artworkData.galleryId,
        eventId: artworkData.eventId,
        includeEventArtworks: true,
        includeArtworksNotInShows: true,
        limit: 99,
        sortBy: 'position',
        locale: getSiteLanguage(),
        fields: 'attachments,cleanTitle,imageUrl,width,height,unitMeasure,medium,displayName,roomType,showInRoom,position,videoId,videoType,verticalOffsetCm,croppedHeaderImages,images' // eslint-disable-line max-len
      })
      if (req.ok) {
        //@ts-ignore
        pageMetaInfo.roomArtworks = req.data
      }
    } catch (err) {
      logger(err)
    }
  }

  pageMetaInfo = {
    ...pageMetaInfo,
    ...artworkData,
    eventDataAssociated: { ...eventDataAssociated },
    openGraphData: {
      //@ts-ignore
      title: `${artworkData.artistName} | ${formatArtworkTitle(artworkData.displayName, artworkData.year, pageMetaInfo.endYear)}`,
      description: sanitizeHtml(artworkData.description || ''),
      imageUrl: artworkData.imageUrl,
      url: urlContentData.uri
    },
    type: urlContentData.data.type
  }
  return pageMetaInfo
}

export default artworkMetaInfo