import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { PageContext } from '@providers/PageContext'
import { useContext, useEffect, useRef, useState } from 'react'
import useContenthubContext from '../context'
import { dateFormatForContentHub } from '@constants'
import dayjs from 'dayjs'
import isTargetingCookiesTypeAccepted from '@utils/isTargetingCookiesAccepted'
import { Tracking } from '@services'
import 'dayjs/locale/zh-tw'
import { parseDOMForTrackingClicks } from '@hooks/useClickTracking'

const useDiscoverMoreData = () => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const { state } = useContenthubContext()
  const labels = useLabelsFromAPI(['News'], siteLanguage)
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'Discover More(Multiple categories)')
  const selectedCategory = currentBlockData?.fields.selectedMode === 0
    ? currentBlockData?.fields?.selectedCategory : currentBlockData?.fields?.selectedCategoryAutoMode

  const prevArrowRef = useRef<HTMLButtonElement | null>(null)
  const nextArrowRef = useRef<HTMLButtonElement | null>(null)
  const [selected, setSelected] = useState(0)
  const splideRef = useRef<{ go: (param: string) => {} } | null>()
  dayjs.locale(siteLanguage === 'zh_CN' ? 'zh-tw' : siteLanguage || '')

  const trackingStoriesIds = (ids) => {
    if (isTargetingCookiesTypeAccepted()) {
      Tracking.trackMultipleActions(40, { itemIds: ids.split(',').map(String) })
      window.removeEventListener('click', parseDOMForTrackingClicks)
      window.addEventListener('click', parseDOMForTrackingClicks)
    }
  }

  useEffect(() => {
    // Attach event listeners when the component mounts
    const storiesIds = currentBlockData?.fields[`stories${selected}`]
    const handlePrevClick = () => {
      setSelected((selected - 1 + 3) % 3)
      splideRef.current?.go('<')
      trackingStoriesIds(storiesIds)
    }

    const handleNextClick = () => {
      setSelected((selected + 1) % 3)
      splideRef.current?.go('>')
      trackingStoriesIds(storiesIds)
    }

    const prevArrowRefCurrent = prevArrowRef.current // Capture the current value
    const nextArrowRefCurrent = nextArrowRef.current // Capture the current value

    // Attach onClick handlers to the arrow elements
    if (prevArrowRefCurrent && nextArrowRefCurrent) {
      prevArrowRefCurrent.addEventListener('click', handlePrevClick)
      nextArrowRefCurrent.addEventListener('click', handleNextClick)
    }

    // Detach event listeners when the component unmounts
    return () => {
      if (prevArrowRefCurrent && nextArrowRefCurrent) {
        prevArrowRefCurrent.removeEventListener('click', handlePrevClick)
        nextArrowRefCurrent.removeEventListener('click', handleNextClick)
      }
    }
  }, [currentBlockData?.fields, selected]) // Include selected in the dependency array

  const dateFEFormat = dateFormatForContentHub[siteLanguage]

  return {
    selectedCategory,
    currentBlockData,
    prevArrowRef,
    nextArrowRef,
    selected,
    labels,
    splideRef,
    dateFEFormat
  }
}

export default useDiscoverMoreData