import React, { PropsWithChildren, ReactNode, useEffect, useMemo, useReducer } from 'react'
import { reducer, StateContext } from '../context'

const ContenthubProvider = ({ children, data }: PropsWithChildren<IContenthubProvider>) => {
  const initialState = useMemo(() => ({
    contentHub: data,
    ctxList: null,
    ctxLoaded: false,
    ctxLoadMore: false,
    ctxInitScroll: false,
    ctxParams: null
  }), [data])

  const refs = {
    // saveAllForms: useRef(null)
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({
      type: 'SET_CONTENT_HUB',
      payload: data
    })
    dispatch({
      type: 'RESET_STORIESLISTING_LIST',
      payload: null
    })
  }, [data])

  return (
    //@ts-ignore
    <StateContext.Provider value={{ state, dispatch, refs }}>
      {children}
    </StateContext.Provider>
  )
}

interface IContenthubProvider {
  children: ReactNode,
  url: string,
  languagesList: ABTypes.Monolith.Locale[],
  data: any
}

export default ContenthubProvider
