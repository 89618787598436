import { createContext, useContext } from 'react'

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STORIES_LOADED':
      return {
        ...state,
        loaded: action.payload
      }
    case 'SET_STORIES':
      return {
        ...state,
        stories: action.payload
      }
    case 'SET_LABELS':
      return {
        ...state,
        moduleLabels: action.payload
      }
    default:
      return { ...state }
  }
}

export const initialState = {
  CMSProps: null,
  moduleLabels: null,
  loaded: null,
  stories: null
}

export const StateContext = createContext(initialState)

export const StoriesCarouselContext = () => useContext(StateContext)
