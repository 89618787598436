export default Object.freeze({
  // Overview Page
  OVERVIEWTIMESPENTINPAGE: 32,
  OVERVIEWSCROLLDOWNINPIXELS: 31,
  OVERVIEWPAGEINDIVIDUALVIEW: 30,
  // Category Pages
  CATEGORYPAGETIMESPENTINPAGE: 36,
  CATEGORYPAGESCROLLDOWNINPIXELS: 35,
  CATEGORYPAGEPAGEINDIVIDUALVIEW: 34,
  // Stories
  STORYINDIVIDUALVIEW: 37,
  STORYTIMESPENTINPAGE: 39,
  STORYSCROLLINPERCENTAGE: 38,
  STORYSCROLLMAXIMUM: 47,
  STORYREFERER: 33
})