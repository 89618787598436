import getProperty from '../getProperty'

const pageNotFoundMetaInfo = async ({ urlContentData }) => {
  const storiesSuggestionContent = getProperty(urlContentData, 'data.values.storiesSuggestionContent')
  let pageMetaInfo = {}

  pageMetaInfo = {
    ...pageMetaInfo,
    ...storiesSuggestionContent,
    type: urlContentData.data.type,
    uri: urlContentData?.uri
  }
  return pageMetaInfo
}

export default pageNotFoundMetaInfo
