import { metadata } from '@constants'
import { FC } from 'react'

const JsonLD: FC<IJsonld> = ({ docType, pageMetaInfo, host, query }) => {
  //@ts-ignore
  const site = 'artbasel'
  const { openGraphData } = pageMetaInfo
  const defaultImage = metadata[site]?.og?.image
  let json
  let hostname = host
  let protocol = 'https'
  //@ts-ignore
  if (hostname?.includes('localhost')) {
    hostname = 'localhost:3000'
    protocol = 'http'
  }
  const parseUrl = (url) => (url[0] === '/' ? `${protocol}://${host}${url}` : url)

  if (docType === 'story') {
    json = {
      '@context': 'http://schema.org/',
      '@type': 'NewsArticle',
      headline: openGraphData
        ? `${openGraphData.title.substring(0, 80)} | ${metadata[site]?.og?.sitename}`
        : metadata[site]?.og?.sitename,
      author: {
        '@type': 'Organization',
        name: metadata[site]?.og?.sitename,
        logo: {
          '@type': 'ImageObject',
          url: defaultImage,
          width: 512,
          height: 512
        }
      },
      datePublished: openGraphData.datePublished,
      publisher: {
        '@type': 'Organization',
        name: metadata[site]?.og?.sitename,
        logo: {
          '@type': 'ImageObject',
          url: defaultImage,
          width: 248,
          height: 60
        }
      },
      description: openGraphData.description,
      url: parseUrl(query)
    }
    if (openGraphData.imageUrl) {
      json.image = {
        '@type': 'ImageObject',
        url: openGraphData.imageUrl,
        width: openGraphData.imageWidth,
        height: openGraphData.imageHeight
      }
    } else {
      json.image = {
        '@type': 'ImageObject',
        url: defaultImage,
        width: 148,
        height: 60
      }
    }
  } else if (docType === 'artworkDetailPage') {
    json = {
      '@context': 'http://schema.org/',
      '@type': 'VisualArtwork',
      name: pageMetaInfo.displayName,
      dateCreated: pageMetaInfo.year,
      image: openGraphData.imageUrl || defaultImage,
      url: parseUrl(query),
      description: openGraphData.description,
      artMedium: pageMetaInfo.medium && pageMetaInfo.medium.name,
      artworkSurface: pageMetaInfo.materials
    }
    if (pageMetaInfo.sponsorArtists && pageMetaInfo.sponsorArtists.length > 0) {
      json.creator = {
        '@type': 'Person',
        name: pageMetaInfo.sponsorArtists[0].artistName,
        url: parseUrl(
          `/catalog/artist/${pageMetaInfo.sponsorArtists[0].artistId}/${pageMetaInfo.sponsorArtists[0].artistCleanTitle}`
        )
      }
    }
    if (pageMetaInfo.sponsorGalleries && pageMetaInfo.sponsorGalleries.length > 0) {
      json.provider = {
        '@type': 'ArtGallery',
        name: pageMetaInfo.sponsorGalleries[0].galleryName,
        url: parseUrl(
          `/catalog/gallery/${pageMetaInfo.sponsorGalleries[0].galleryId}/${pageMetaInfo.sponsorGalleries[0].galleryCleanTitle}`
        ),
        image: defaultImage
      }
    }
    if (pageMetaInfo.height) {
      json.height = [
        {
          '@type': 'Distance',
          name: `${pageMetaInfo.height} ${pageMetaInfo.unitMeasure}`
        }
      ]
    }
    if (pageMetaInfo.width) {
      json.width = [
        {
          '@type': 'Distance',
          name: `${pageMetaInfo.width} ${pageMetaInfo.unitMeasure}`
        }
      ]
    }
  } else if (docType === 'galleryDetailPage') {
    json = {
      '@context': 'http://schema.org/',
      '@type': 'WebPage',
      name: `${pageMetaInfo.displayName} | Art Basel`,
      description: pageMetaInfo.description,
      url: parseUrl(query),
      image: openGraphData.imageUrl
    }
  } else if (docType === 'artistDetailPage') {
    json = {
      '@context': 'http://schema.org/',
      '@type': 'Person',
      name: pageMetaInfo.name,
      familyName: pageMetaInfo.lastName,
      givenName: pageMetaInfo.firstName,
      alternateName: pageMetaInfo.otherName,
      country: pageMetaInfo.country,
      birthDate: pageMetaInfo.birthYear,
      url: parseUrl(query)
    }
  } else {
    json = {
      '@context': 'http://schema.org/',
      '@type': 'WebPage',
      name: openGraphData.title,
      description: openGraphData.description,
      url: parseUrl(query),
      image: defaultImage
    }
  }

  return (
    <script
      type='application/ld+json'
      dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
    />
  )
}

interface IJsonld {
  pageMetaInfo?: any,
  docType?: string,
  host?: string | false,
  referer?: string,
  query?: string | string[]
}

export default JsonLD
