import CustomLinkTag from '@components/CustomLinkTag'

const LinkForSpotlight = ({ url, storyId, children }) =>
  <CustomLinkTag
    url={url}
    data-mch-tracking={true}
    data-mch-tracking-action-click={44}
    data-mch-tracking-action-screenprint={40}
    data-mch-tracking-measure={storyId}
    data-mch-tracking-measure2='spotlight'
  >{children}</CustomLinkTag>

export default LinkForSpotlight