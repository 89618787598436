import React, { useContext } from 'react'
import { EditorialCard, Container, Row, TextLink } from '@mch-group/uikit-components'
import CustomLinkTag from '@components/CustomLinkTag'
import useContenthubContext from '../context'
import { formatArticleLink } from '@utils'
import { cloudinary, dateFormatForContentHub } from '@constants'
import dayjs from 'dayjs'
import { PageContext } from '@providers/PageContext'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import 'dayjs/locale/zh-tw'
import GlobalStyle from './common/styles/CHCommonLayoutStyles'

const CHInpartnershipwith = () => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const labels = useLabelsFromAPI(['News', 'AltTags'], siteLanguage)
  const { state } = useContenthubContext()
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'In Partnership with')
  const inPartnershipWithData = currentBlockData?.fields.APIResults[0]?.data?.items
  dayjs.locale(siteLanguage === 'zh_CN' ? 'zh-tw' : siteLanguage || '')
  const dateFEFormat = dateFormatForContentHub[siteLanguage]
  return (
    currentBlockData ?
      <Container className='px-5 px-lg-8 px-md-7 mb-11 mb-lg-12 mb-xl-14 pt-5' id='inpartnershipwith'>
        <Row className='justify-content-center mt-0 pt-5'>
          <>
            <div className='d-flex justify-content-between mt-0'>
              <h2 className='heading'>{currentBlockData?.fields.title}</h2>
              <CustomLinkTag url={currentBlockData?.fields?.viewAllUrl}
                ariaLabel={labels?.labels?.AltTags?.genericViewAll}>
                <TextLink useLink={true} icon='arrow-right' iconAlign='right' >
                  {currentBlockData?.fields?.viewAllLabel}
                </ TextLink>
              </CustomLinkTag>
            </div>
            <div className='stories-container'>
              {inPartnershipWithData?.map((stories, i) => (
                <div className={i === 0 ? 'large-card' : `small-card${i}`} key={stories?.id}>
                  <EditorialCard
                    imgLoading='lazy'
                    layout='horizontal'
                    size={i === 0 ? 'large' : 'small'}
                    language={siteLanguage as any}
                    a11yData={{
                      ariaLabel: stories?.title,
                      imageAlt: stories?.title
                    }}
                    link={stories?.redirectUrl
                      ? stories.redirectUrl : formatArticleLink(stories?.url)}
                    urlImage={`${cloudinary.throughCloudfront(`https://res.cloudinary.com/${getEnvConfig.fe.cloudinary.name}/image/fetch/${i === 0 ? 'w_900,q_auto' : 'w_400,q_auto'}/${stories.imageUrl}`)}`}
                    heading={stories?.title}
                    description={stories?.description}
                    date={dayjs(stories?.publishedDate, 'YYYY-MM-DD').format(dateFEFormat)?.replace('.', '')}
                    textColour='black'
                    ctaLabel={stories?.contentHubCallToAction || labels?.labels?.News?.ReadMoreCTA}
                    author={stories?.authorName}
                    linkRenderer={(link, label, target, ariaLabel) => (
                      <CustomLinkTag
                        url={link}
                        ariaLabel={ariaLabel}
                        data-mch-tracking={true}
                        data-mch-tracking-action-click={44}
                        data-mch-tracking-action-screenprint={40}
                        data-mch-tracking-measure={stories?.id}
                        data-mch-tracking-measure2='inpartnershipwith'
                      >
                        {label}
                      </CustomLinkTag>
                    )}
                  />
                </div>
              ))}
            </div>
          </>
        </Row>
        <GlobalStyle />
      </Container>
      : null
  )
}

export default CHInpartnershipwith
