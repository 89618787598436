import { Cms as cmsService } from '@services/index'
import { logger } from '@utils/index'
import { NextPageContext } from 'next'

// TODO: Explain in plain words what these function does, where is it called, etc.
export const getWhatToDoForUrl = async ({
  req,
  uri,
  language,
  query
}: {
  req: NextPageContext['req']
  uri: string | undefined
  language: string | null | undefined
  query: NextPageContext['query']
}): Promise<{ data: any; uri: any } | null> => {
  const isServer = typeof window === 'undefined'

  let targetUri = isServer ? req?.url : uri
  // slug is the current page path
  let slug = query?.slug && typeof query.slug === 'string' ? query.slug : ''
  if (!targetUri?.includes('DraftId')) {
    targetUri = targetUri?.split('?')[0]
  }

  if (['/home', '/app', '/lens'].includes(slug) || slug.startsWith('/artdiscovery')) {
    return {
      data: {
        type: 'deeplink',
        values: {
          link: {
            ios: 'https://itunes.apple.com/us/app/art-basel-official-app/id1050156803?mt=8',
            android: 'https://play.google.com/store/apps/details?id=com.mch.artbasel&hl=en',
            web: '/artbaselapp'
          }
        }
      },
      uri: targetUri
    }
  }

  try {
    const urlContentData = await cmsService.getContentByUrl(encodeURI(targetUri || ''), language)
    if (urlContentData.ok) {
      return { data: urlContentData.data, uri: targetUri }
    }
  } catch (e) {
    logger(e)
  }
  return null
}

export default getWhatToDoForUrl
