import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  .stories-wrapper {
    margin: 16px 0 56px 0;

    @media (min-width: ${global.uikitVars.breakpointLg}) {
      margin: 16px 0 64px 0;
    }

    @media (min-width: ${global.uikitVars.breakpointXl}) {
      margin: 16px 0 80px 0;
    }
  }

  .stories-container {
    @media (min-width: ${global.uikitVars.breakpointXs}) {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 24px;
    }

    @media (min-width: ${global.uikitVars.breakpointMd}) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 8px;
      margin-top: 32px;
    }

    @media (min-width: ${global.uikitVars.breakpointLg}) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 8px;
    }

    @media (min-width: ${global.uikitVars.breakpointXl}) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 8px;
      margin-top: 40px;
    }

    .large-card {
      @media (min-width: ${global.uikitVars.breakpointXs}) {
        width: 100%;
        padding-bottom: 32px;
      }

      @media (min-width: ${global.uikitVars.breakpointMd}) {
        grid-area: 1 / 1 / 4 / 8;
        padding-bottom: 0;
      }

      @media (min-width: ${global.uikitVars.breakpointLg}) {
        grid-area: 1 / 1 / 4 / 8;
      }

      @media (min-width: ${global.uikitVars.breakpointXl}) {
        grid-area: 1 / 1 / 4 / 8;
      }
    }

    .small-card1 {
      @media (min-width: ${global.uikitVars.breakpointXs}) {
        width: 100%;
        padding-bottom: 8px;
      }

      @media (min-width: ${global.uikitVars.breakpointMd}) {
        grid-area: 1 / 8 / 2 / 13;
        padding-bottom: 0;
      }

      @media (min-width: ${global.uikitVars.breakpointLg}) {
        grid-area: 1 / 8 / 2 / 13;
      }

      @media (min-width: ${global.uikitVars.breakpointXl}) {
        grid-area: 1 / 8 / 2 / 13;
      }
    }

    .small-card2 {
      @media (min-width: ${global.uikitVars.breakpointXs}) {
        width: 100%;
        padding-bottom: 8px;
      }

      @media (min-width: ${global.uikitVars.breakpointMd}) {
        grid-area: 2 / 8 / 3 / 13;
        padding-bottom: 0;
      }

      @media (min-width: ${global.uikitVars.breakpointLg}) {
        grid-area: 2 / 8 / 3 / 13;
      }

      @media (min-width: ${global.uikitVars.breakpointXl}) {
        grid-area: 2 / 8 / 3 / 13;
      }
    }

    .small-card3 {
      @media (min-width: ${global.uikitVars.breakpointXs}) {
        width: 100%;
      }

      @media (min-width: ${global.uikitVars.breakpointMd}) {
        grid-area: 3 / 8 / 4 / 13;
      }

      @media (min-width: ${global.uikitVars.breakpointLg}) {
        grid-area: 3 / 8 / 4 / 13;
      }

      @media (min-width: ${global.uikitVars.breakpointXl}) {
        grid-area: 3 / 8 / 4 / 13;
      }
    }
  }
`

export default GlobalStyle
