import React from 'react'
import { EditorialBanner, Row, Col } from '@mch-group/uikit-components'
import { Media, MediaContextProvider } from '@responsive'

import CustomLinkTag from '@components/CustomLinkTag'

import { formatArticleLink } from '@utils'
import useHighlightedStory from './CHSpotlight/hooks/useHighlightedStory'

const CHHighlightedStory = () => {
  const { currentBlockData, bannerImageDesktop, bannerImageMobile, labels } = useHighlightedStory()
  return (
    currentBlockData ?
      <MediaContextProvider>
        <div className='mb-7 mb-lg-8' id='highlightedstory'>
          <Row className='justify-content-center mt-0'>
            <Col className='mt-0'>
              <Media greaterThanOrEqual='md'>
                <EditorialBanner
                  link={currentBlockData?.fields?.storiesWithAPIResults[0]?.redirectUrl
                    ? currentBlockData?.fields?.storiesWithAPIResults[0]?.redirectUrl
                    : formatArticleLink(currentBlockData?.fields?.storiesWithAPIResults[0]?.url)}
                  urlImage={bannerImageDesktop}
                  placeholderType='image'
                  heading={currentBlockData?.fields?.storiesWithAPIResults[0]?.title}
                  ctaLabel={currentBlockData?.fields?.storiesWithAPIResults[0]?.contentHubCallToAction ||
                    labels?.labels?.News?.ReadMoreCTA}
                  eyebrow={currentBlockData?.fields.title}
                  mediaType='image'
                  headingStyle='default'
                  ctaAriaLabel={`${labels?.labels?.AltTags?.genericViewStory} ${currentBlockData?.fields?.storiesWithAPIResults[0]?.title}`}
                  linkRenderer={(link, children) =>
                    <CustomLinkTag
                      url={link}
                      data-mch-tracking={true}
                      data-mch-tracking-action-click={44}
                      data-mch-tracking-action-screenprint={40}
                      data-mch-tracking-measure={currentBlockData?.fields?.storiesWithAPIResults[0]?.id}
                      data-mch-tracking-measure2='highlightedstory'
                    >
                      {children}
                    </CustomLinkTag>
                  }
                />
              </Media>
              <Media lessThan='md'>
                <EditorialBanner
                  link={currentBlockData?.fields?.storiesWithAPIResults[0]?.redirectUrl
                    ? currentBlockData?.fields?.storiesWithAPIResults[0]?.redirectUrl
                    : formatArticleLink(currentBlockData?.fields?.storiesWithAPIResults[0]?.url)}
                  urlImage={bannerImageMobile}
                  placeholderType='image'
                  heading={currentBlockData?.fields?.storiesWithAPIResults[0]?.title}
                  ctaLabel={currentBlockData?.fields?.storiesWithAPIResults[0]?.contentHubCallToAction ||
                    labels?.labels?.News?.ReadMoreCTA}
                  eyebrow={currentBlockData?.fields.title}
                  mediaType='image'
                  headingStyle='default'
                  ctaAriaLabel={`${labels?.labels?.AltTags?.genericViewStory} ${currentBlockData?.fields?.storiesWithAPIResults[0]?.title}`}
                  linkRenderer={(link, children) =>
                    <CustomLinkTag
                      url={link}
                      data-mch-tracking={true}
                      data-mch-tracking-action-click={44}
                      data-mch-tracking-action-screenprint={40}
                      data-mch-tracking-measure={currentBlockData?.fields?.storiesWithAPIResults[0]?.id}
                    >
                      {children}
                    </CustomLinkTag>
                  }
                />
              </Media>
            </Col>
          </Row>
        </div>
      </MediaContextProvider>
      : null
  )
}


export default CHHighlightedStory
