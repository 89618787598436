import { useTracking } from '@hooks'
import { Tracking } from '@services'
import calculateScrollPercentage from '@utils/calculateScrollPercentage'
import isTargetingCookiesTypeAccepted from '@utils/isTargetingCookiesAccepted'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const useTrackingSpaceScrolledDown =
  (action: number, id: number | string | null, scrollInPercentage: boolean): void => {
    const router = useRouter()
    const { setAction } = useTracking(action)
    useEffect(() => {
      const handleRouteChange = () => {
        const scroll = scrollInPercentage ? Math.floor(calculateScrollPercentage()) : window.scrollY
        const params = { measure: scroll }
        isTargetingCookiesTypeAccepted() && Tracking.trackAction(action, id, params)
      }
      if (id) {
        router.events.on('routeChangeStart', handleRouteChange)
      }

      return () => {
        if (id) {
          router.events.off('routeChangeStart', handleRouteChange)
        }
      }
    }, [router, setAction, action, id, scrollInPercentage])
  }

export default useTrackingSpaceScrolledDown