const defineLimit = (value) => {
  let limit
  switch (value) {
    case '4':
    case 'carousel':
      limit = 4
      break
    case '3':
    case '3n':
      limit = 3
      break
    case '2':
    case '2n':
      limit = 2
      break
    default:
      break
  }
  return limit
}

export default defineLimit