import ContenthubProvider from '../provider'
import CHInfocus from '../views/CHInfocus'
import CHLatestStories from '../views/CHLatestStories'
import CHMostPopular from '../views/CHMostPopular'
import CHNewsletter from '../views/CHNewsletter'
import CHPartners2 from '../views/CHPartners2'
import CHPartners1 from '../views/CHPartners1'
import CHSpotlight from '../views/CHSpotlight'
import CHTheArtMarket from '../views/CHTheArtMarket'
import CHVideo from '../views/CHVideo'
import CHInpartnershipwith from '../views/CHInpartnershipwith'
import CHRecommendedForYou from '../views/CHRecommendedForYou'
import CHHighlightedCategoryCarousel from '../views/CHHighlightedCategoryCarousel'
import CHHighlightedCategoryListing from '../views/CHHighlightedCategoryListing'
import CHPartners3 from '../views/CHPartners3'
import CHDiscoverMore from '../views/CHDiscoverMore'
import { memo } from 'react'
import CHBannerInternal1 from '../views/CHBannerInternal1'
import CHBannerInternal2 from '../views/CHBannerInternal2'
import CHBannerInternal3 from '../views/CHBannerInternal3'
import CHBannerInternal4 from '../views/CHBannerInternal4'

const List1 = ({ data }) =>
  <ContenthubProvider url='/contenthub' languagesList={[]} data={data}>
    <CHSpotlight />
    <CHLatestStories moduleName='Latest Stories' />
    <CHPartners1 />
    <CHBannerInternal1 />
    <CHInfocus />
    <CHPartners2 />
    <CHBannerInternal2 />
    <CHMostPopular />
    <CHTheArtMarket />
    <CHNewsletter moduleName='Newsletter(Mid Position)' />
    <CHVideo />
    <CHPartners2 />
    <CHBannerInternal3 />
    <CHInpartnershipwith />
    <CHRecommendedForYou />
    <CHPartners3 />
    <CHBannerInternal4 />
    <CHHighlightedCategoryCarousel />
    <CHHighlightedCategoryListing />
    <CHNewsletter moduleName={'Newsletter(Footer Position)'} />
    <CHDiscoverMore />
  </ContenthubProvider>

export default memo(List1)