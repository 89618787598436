import React from 'react'
import { Container, Row } from '@mch-group/uikit-components'
import useContenthubContext from '../context'
import { Text } from '@components'

const CHPartners2 = () => {
  const { state } = useContenthubContext()
  const currentBlockData = state.contentHub.formerData.modules.find((item) => item.name === 'Partners Banners (Second Position)')
  return (
    currentBlockData ?
      <Container className='px-5 px-lg-8 px-md-7 mb-10' id='partners2'>
        <Row className='justify-content-center mt-0'>
          <Text isHtml forwardedAs='div' style={{ background: 'blue', width: '500px', height: '100px', color: 'white', marginTop: 0 }}>{currentBlockData?.fields.dblClickScript}</Text>
        </Row>
      </Container>
      : null
  )
}


export default CHPartners2