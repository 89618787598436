import useCHSpotlightMobile from './hooks/useCHSpotlightMobile'
import { EditorialStories } from '@mch-group/uikit-components'
import PreloadImages from '@components/reusable-modules/PreloadImage'

const CHSpotlightMobile = () => {
  const { objectToRenderMobile, fields, labels } = useCHSpotlightMobile()
  const AltTagsLabels = labels?.labels?.AltTags
  return (
    objectToRenderMobile?.length > 0 ?
      <>
        <EditorialStories
          h1={fields?.selectedMode === 0 ? fields?.pageTitle : fields?.pageTitleAutomaticMode}
          a11yData={{
            pauseBtn: AltTagsLabels?.pauseAnimationText,
            playBtn: AltTagsLabels?.playAnimationText,
            previousBtn: AltTagsLabels?.prevStoryText,
            nextBtn: AltTagsLabels?.nextStoryText
          }}
          stories={objectToRenderMobile}
        />
        {objectToRenderMobile.map((storyItem) =>
          <PreloadImages
            imgUrl={storyItem.backgroundImage}
            key={storyItem.heading}
          />)}
      </> : null
  )
}

export default CHSpotlightMobile