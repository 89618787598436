import useSWR from 'swr'
import { useContext } from 'react'
import { PageContext } from '@providers/PageContext'

const useTranslatedCategory = (selectedCategoryId) => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const { data: translatedData } = useSWR(selectedCategoryId && `msvc/v1/translations/CHCategory/${selectedCategoryId}`)
  const translatedCategory: any = []
  if (siteLanguage !== 'en') {
    translatedData?.map((data) => {
      if (data.key.localeCode === siteLanguage) {
        translatedCategory.push(data)
      }
    })
  }
  return {
    translatedCategory
  }
}

export default useTranslatedCategory