import { Tracking } from '@services'
import calculateScrollPercentage from '@utils/calculateScrollPercentage'
import isTargetingCookiesTypeAccepted from '@utils/isTargetingCookiesAccepted'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

/**
 * This hook is used to track the maximum scroll down in pixels or percentage
  It is used ONLY in the Story pages
  It executes the tracking action when the user leaves the page
  This makes it a bit sophisticate because it triggers "on abandon page" after click ANY link, not "onclick"
 */
const useTrackingScrollDownInPercentage =
  (action: number, id: number | string | null, pageType: string): void => {
    const router = useRouter()
    const maxScrollPositionRef = useRef(0)
    const updateValue = _.debounce(() => {
      const currentScrollPercentage = Math.floor(calculateScrollPercentage())
      if (currentScrollPercentage > maxScrollPositionRef.current) {
        maxScrollPositionRef.current = currentScrollPercentage
      }
    }, 800)

    useEffect(() => {
      pageType === 'story' && window.addEventListener('scroll', updateValue, { passive: true })

      return () => {
        pageType === 'story' && window.removeEventListener('scroll', updateValue)
      }
    }, [pageType, updateValue])

    useEffect(() => {
      const handleRouteChange = () => {
        const params = { measure: maxScrollPositionRef.current }
        if (pageType === 'story') {
          isTargetingCookiesTypeAccepted() && Tracking.trackAction(action, id, params)
          // Resetting to zero after a Tracking has been done
          maxScrollPositionRef.current = 0
        }
      }
      if (id) {
        pageType === 'story' && router.events.on('routeChangeStart', handleRouteChange)
      }

      return () => {
        if (id) {
          pageType === 'story' && router.events.off('routeChangeStart', handleRouteChange)
        }
      }
    }, [router, action, id, pageType, maxScrollPositionRef])
  }

export default useTrackingScrollDownInPercentage